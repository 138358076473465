import React, {useState} from "react";
import AdBlock from "../../components/adBlock";
import {useHistory, useLocation} from 'react-router-dom';
import {Button} from "react-bootstrap";
import {useAuth} from "../../context/provideAuth";
import request from "../../tools/ajaxManager";
import triggerBrowserReflow from "react-bootstrap/triggerBrowserReflow";

const PasswordResetScreen = function (props) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSend = (e) => {
        e.preventDefault();

        let data = new FormData();
        data.append('email', email);

        request(
            '/security/password/reset',
            'POST',
            data,
            {},
            async (response) => {
                setSuccess('Вам на почту выслан новый пароль!')
            },
            (err) => {
                setSuccess('Вам на почту выслан новый пароль!')
                // console.log('ERROR', err)
                // setError(err?.message ?? '')
            }
        );
    }

    return <div className={'indexPage'}>
        <div className={'card1'}>
            <div>
                <div className={'card1Tab'}>
                    <span>Сброс пароля</span>
                </div>
            </div>
            <div className={'divider'}/>
            <div className={'loginForm'}>
                <form onSubmit={handleSend}>
                    <input onChange={(e) => setEmail(e.target.value)}
                           type="email" required={true}
                           placeholder="Адрес электронной почты"/>

                    <Button onClick={handleSend} variant="custom" type="submit">
                        Сбросить пароль
                    </Button>
                    {error && <p className={'alert alert-danger mt-3'} role={'alert'}>{error}</p>}
                    {success && <p className={'alert alert-success mt-3'} role={'alert'}>{success}</p>}
                </form>
            </div>
        </div>
    </div>;
}

export default PasswordResetScreen;
