import {Breadcrumb, Table, Button} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../tools/ajaxManager";
import CustomPagination from "../../components/customPagination";

const AdminFeedbackList = function (props) {
    // state
    let auth = useAuth();
    const [feedbacks, setFeedbacks] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    // effects
    useEffect(() => {
        if (auth.token) request(
            '/feedback/?offset=' + ((page - 1) * 30),
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                setFeedbacks(response.data);
                setCount(response.count);
            },
            (error) => {
            },
        );
    }, [auth.token, page]);

    // functions
    function handleProcess(key) {
        if (auth.token) request(
            '/feedback/' + feedbacks[key].id,
            'PUT',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...feedbacks];
                tmp[key] = response.data;
                setFeedbacks(tmp);
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }
    function handleDelete(key) {
        if (auth.token) request(
            '/feedback/' + feedbacks[key].id,
            'DELETE',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...feedbacks];
                tmp.splice(key, 1);
                setFeedbacks(tmp);
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }

    return <div className={'content'}>
        <h3>Список заявок на контакт</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список заявок на контакт</Breadcrumb.Item>
        </Breadcrumb>

        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Контент</th>
                    <th>Контакты</th>
                    <th>Статус</th>
                    <th>Дата</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {feedbacks.map((item, key) => {
                    let date = new Date(item.created);
                    return <tr key={key}>
                        <td>{((page - 1) * 30) + (key + 1)}</td>
                        <td>{item.content}</td>
                        <td>
                            {item.email && <span><FontAwesomeIcon icon={'envelope'} className={'mr-2'}/> Почта: <a href={'mailto:' + item.email}>{item.email}</a></span>}
                            {item.phone && <span>{item.email && <br/>}<FontAwesomeIcon icon={'phone'} className={'mr-2'}/> Телефон: <a href={'tel:' + item.phone}>{item.phone}</a></span>}
                        </td>
                        <td>
                            {item.processed ? <div>
                                <FontAwesomeIcon icon={'check-circle'} className={'text-success'}/>
                                <span className={'ml-2 text-success'}>Заявка обработана</span>
                            </div> : <div>
                                <Button className={'text-right'} variant="outline-primary" size={'sm'}
                                        onClick={() => handleProcess(key)}>
                                    <FontAwesomeIcon icon={'file-signature'}/>
                                    Обработать
                                </Button>
                            </div>}
                        </td>
                        <td>{date.getHours()}:{date.getMinutes()} {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</td>
                        <td>
                            <Button className={'text-right'} variant="danger" size={'sm'}
                                    onClick={() => handleDelete(key)}>
                                <FontAwesomeIcon icon={'trash'}/>
                            </Button>
                        </td>
                    </tr>;
                })}
                </tbody>
            </Table>
        </div>

        <CustomPagination update={setPage} count={Math.ceil(count / 30)}/>
    </div>;
}

export default AdminFeedbackList;