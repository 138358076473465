import {connect} from "react-redux";
import MainImg from '../../images/main_page_photo.webp'

const Instruction = function (props) {
    return <div className={'indexPage'}>
        <h3>Как удалить свой аккаунт</h3>
        <p>Для удаления аккаунта Вам необходимо написать в техническую поддержку в разделе "Сервисы" и попросить об удалении аккаунта.</p>
    </div>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(Instruction);