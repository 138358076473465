import {connect} from "react-redux";
import {useAuth} from "../context/provideAuth";
import {useEffect, useState} from "react";
import request from "../tools/ajaxManager";
import {serverAdUrl} from "../tools/parameters";

const AdBlock = function (props) {
    let auth = useAuth();
    const [data, setData] = useState(null);

    // effects
    useEffect(() => {
        request(
            '/partner/notice/show',
            'GET',
            {},
            {},
            (response) => {
                setData(response.data);
            },
            (error) => {
            },
        );
    }, [auth.token]);

    const handleClick = (e, data) => {
        e.preventDefault();

        request(
            '/partner/notice/go/' + data.id,
            'GET',
            {},
            {}
        );

        window.open(data.link, '__blank');
    }

    return <div className={'moneyBlock'}>
        {data ? (data.filter(item => item.isShow && item.type === 'big_ad').length > 0 ?
            <a href={data.find(item => item.isShow && item.type === 'big_ad').link} target={'__blank'} onClick={(e) => {
                handleClick(e, data.find(item => item.isShow && item.type === 'big_ad'));
            }}>
                <img src={serverAdUrl + data.find(item => item.isShow && item.type === 'big_ad').image}
                     alt={'Рекл.'} className={'moneyItem big'}/>
            </a> :
            <div className={'moneyItem big'}>
            <span className={'little'}>
                Здесь может быть
            </span>
                <span className={'big'}>
                ВАША
            </span>
                <span className={'big'}>
                РЕКЛАМА
            </span>
                <span className={'middle'}>
                260*480
            </span>
                <span className={'middle'}>
                <a href={'tel:+79173884339'}>+7 (917) 388-43-39</a>
            </span>
            </div>) : null}

        {data ? (data.filter(item => item.isShow && item.type === 'small_ad').length > 0 ?
            <a href={data.find(item => item.isShow && item.type === 'small_ad').link} target={'__blank'} onClick={(e) => {
                handleClick(e, data.find(item => item.isShow && item.type === 'small_ad'));
            }}>
                <img src={serverAdUrl + data.find(item => item.isShow && item.type === 'small_ad').image}
                     alt={'Рекл.'} className={'moneyItem small'}/>
            </a> :
            <div className={'moneyItem small'}>
                <span className={'little'}>
                    Рекламное место
                </span>
                    <span className={'big'}>
                    СВОБОДНО
                </span>
                    <span className={'middle'}>
                    260*480
                </span>
                    <span className={'middle'}>
                    <a href={'tel:+79173884339'}>+7 (917) 388-43-39</a>
                </span>
            </div>) : null}
    </div>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(AdBlock)