import {Breadcrumb, Table, Button, InputGroup, Form, Alert} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../tools/ajaxManager";
import AdminColorTr from "../../components/AdminColorTr";

const AdminColors = function (props) {
    // state
    let auth = useAuth();
    const [data, setData] = useState([]);
    const [allFlowerColors, setAllFlowerColors] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [title, setTitle] = useState('');
    const [code, setCode] = useState('');

    // effects
    useEffect(() => {
        if (auth.token) {
            request(
                '/color/',
                'GET',
                {},
                {
                    "YT-AUTH-TOKEN": "YourTar " + auth.token,
                },
                (response) => {
                    setData(response.data);

                    request(
                        '/flower/?colorsNeed=1',
                        'GET',
                        {},
                        {
                            "YT-AUTH-TOKEN": "YourTar " + auth.token,
                        },
                        (response2) => {
                            let tmp = Object.values(response2.colors);

                            response.data.forEach(obj => {
                               if (tmp.indexOf(obj.code) !== -1) {
                                   tmp.splice(tmp.indexOf(obj.code), 1);
                               }
                            });

                            setAllFlowerColors(tmp);
                        },
                        (error) => {
                        },
                    );
                },
                (error) => {
                },
            );
        }
    }, [auth.token]);

    // functions
    function handleAdd() {
        if (auth.token) {
            let formData = new FormData();
            formData.append('name', title);
            formData.append('code', code);

            request(
                '/color/',
                'POST',
                formData,
                {
                    "YT-AUTH-TOKEN": "YourTar " + auth.token,
                },
                (response) => {
                    let tmp = [...data];
                    tmp.push(response.data);

                    setIsAdd(false);
                    setData(tmp);
                    setCode('');
                    setTitle('');
                },
                (error) => {
                    alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
                },
            );
        }
    }

    function handleDelete(key) {
        if (auth.token) request(
            '/color/' + data[key].id,
            'DELETE',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...data];
                tmp.splice(key, 1);
                setData(tmp);
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }

    function renderAddRow() {
        if (isAdd) {
            return <tr>
                <td></td>
                <td>
                    <Form.Group controlId="name">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Название
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control value={title} onChange={(e) => setTitle(e.target.value)}
                                          type="text"/>
                        </InputGroup>
                    </Form.Group>
                </td>
                <td>
                    <Form.Group controlId="name">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Код
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control value={code} onChange={(e) => setCode(e.target.value)}
                                          type="text"/>
                        </InputGroup>
                    </Form.Group>
                </td>
                <td>
                    <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                        setIsAdd(false);
                        setCode('')
                        setTitle('');
                    }}>
                        <FontAwesomeIcon icon={'undo'} className={'mr-2'}/>
                        Отмена
                    </Button>
                    <Button className={'text-right'} variant="success" size={'sm'}
                            onClick={() => handleAdd()}>
                        <FontAwesomeIcon icon={'check'} className={'mr-2'}/>
                        Сохранить
                    </Button>
                </td>
            </tr>;
        } else {
            return <tr>
                <td colSpan={6}>
                    <Button className={'text-right'} variant="success" size={'sm'}
                            onClick={() => setIsAdd(true)}>
                        <FontAwesomeIcon icon={'plus'} className={'mr-2'}/>
                        Добавить
                    </Button>
                </td>
            </tr>
        }
    }

    return <div className={'content'}>
        <h3>Список цветов</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список цветов</Breadcrumb.Item>
        </Breadcrumb>

        <Alert variant={'secondary'}>
            Неразмеченные цвета:
            {allFlowerColors.map((color, key) => {
                return <span key={color} className={'d-inline-block mr-1 ml-1'} style={{
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    backgroundColor: color,
                    border: '1px solid black',
                }} onClick={() => {
                    setCode(color);
                    setIsAdd(true);
                }} />
            })}
        </Alert>

        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Название</th>
                    <th>Код</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {renderAddRow()}
                {data.map((item, key) => {
                    return <AdminColorTr num={key} key={item.id} data={item} delete={handleDelete} edit={handleAdd}/>;
                })}
                </tbody>
            </Table>
        </div>
    </div>;
}

export default AdminColors;