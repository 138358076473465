import React from "react";
import {
    Route,
    Redirect,
} from "react-router-dom";

import {useAuth} from "../../context/provideAuth";

const AdminRoute = function({ children, ...rest }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user && (auth.user.roles.includes('ROLE_ADMIN') ||
                auth.user.roles.includes('ROLE_SUPER_ADMIN')) ? (
                    children
                ) : auth.user ? (
                    <Redirect
                        to={{
                            pathname: "/404",
                            state: { from: location }
                        }}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default AdminRoute;