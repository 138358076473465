import {Pagination} from "react-bootstrap";
import {useEffect, useState} from "react";

const CustomPagination = function (props) {
    // state
    const [page, setPage] = useState(props.currentPage);

    useEffect(() => {
        if (page !== props.currentPage)
            setPage(props.currentPage);
    }, [props.currentPage]);

    // functions
    function paginationBtnsRender() {
        let arr = [];
        let pages = props.count;

        for (let i = 0; i < pages; i++) {
            if (i < 2 || (i + 1) > (pages - 2) || ((page - (i + 1)) < 3 && ((i + 1) - page) < 3))
                arr.push(<Pagination.Item key={i} onClick={() => {
                    setPage(i + 1);
                    props.update(i + 1)
                }} active={(i + 1) === page}>
                    {(i + 1)}
                </Pagination.Item>);
            if ((i === 2 && page > 5) || (i === (pages - 3) && page < (pages - 4)))
                arr.push(<Pagination.Ellipsis key={i} />);
        }

        return arr;
    }

    if (parseInt(props.count) === 0) return null;

    return <Pagination>
        <Pagination.First onClick={() => {
            setPage(1);
            props.update(1);
        }} disabled={page < 2}/>
        <Pagination.Prev onClick={() => {
            if (page > 1) {
                setPage(page - 1);
                props.update(page - 1);
            }
        }} disabled={page < 2}/>
        {paginationBtnsRender()}
        <Pagination.Next onClick={() => {
            if (page < props.count) {
                setPage(page + 1);
                props.update(page + 1);
            }
        }} disabled={page === props.count}/>
        <Pagination.Last onClick={() => {
            setPage(props.count);
            props.update(props.count);
        }}
                         disabled={page === props.count}/>
    </Pagination>
}

export default CustomPagination;