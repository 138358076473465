import {connect} from "react-redux";
import {serverAdUrl} from "../tools/parameters";
import {Button, Col, Container, Form, InputGroup, Modal, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {createRef, useEffect, useState} from "react";
import Close from "../images/modal_close.webp";
import {useAuth} from "../context/provideAuth";
import request from "../tools/ajaxManager";
import {months} from '../tools/data';

const AdminAdTr = function (props) {
    let auth = useAuth();
    const [data, setData] = useState(props.data);
    const [isEdit, setIsEdit] = useState(false);
    const [link, setLink] = useState('');
    const [isShow, setIsShow] = useState(true);
    const [type, setType] = useState(props.data.type);
    const [show, setShow] = useState(false);
    const [stats, setStats] = useState(null);

    const filesRef = createRef();

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    const getStats = () => {
        if (auth.token && !stats) request(
            '/partner/notice/stats/' + data.id,
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => { console.log(response)
                setStats(response.data);
            },
            (error) => {
            },
        );
    }

    if (isEdit) {
        return <tr>
            <td>{props.num + 1}</td>
            <td>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Ссылка
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setLink(e.target.value)}
                                      type="text" defaultValue={data.link} />
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Form.Control placeholder="Выберите файлы" type={'file'} ref={filesRef} />
            </td>
            <td>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Тип
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={(e) => {
                            setType(e.target.value);
                        }} defaultValue={data.type}>
                            <option value={'big_ad'}>Большой баннер</option>
                            <option value={'small_ad'}>Маленький баннер</option>
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
            </td>
            <td></td>
            <td>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" defaultChecked={data.isShow} label="Показывать на сайте" onChange={(e) => {
                        setIsShow(e.target.checked);
                    }}/>
                </Form.Group>
            </td>
            <td>
                <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                    setIsEdit(false);
                    setLink(data.link);
                    setIsShow(data.isShow);
                    setType(data.type);
                }}>
                    <FontAwesomeIcon icon={'undo'} className={'mr-2'}/>
                    Отмена
                </Button>
                <Button className={'text-right'} variant="success" size={'sm'}
                        onClick={() => {
                            let formData = new FormData();
                            formData.append('id', data.id);
                            if (data.link !== link && link.length > 0)
                                formData.append('link', link);
                            if (type !== data.type)
                                formData.append('type', type);
                            if (data.isShow !== isShow)
                                formData.append('is_show', isShow ? 1 : 0);
                            if (filesRef.current.files.length > 0)
                                formData.append('file', filesRef.current.files[0]);
                            props.edit(formData, props.num);
                            setIsEdit(false);
                        }}>
                    <FontAwesomeIcon icon={'check'} className={'mr-2'}/>
                    Сохранить
                </Button>
            </td>
        </tr>
    }

    return <tr >
        <td>{props.num + 1}</td>
        <td style={{maxWidth: 200, wordWrap: 'break-word'}}><a href={data.link} target={'__blank'}>{data.link}</a></td>
        <td><img alt={'notice_' + data.id} src={serverAdUrl + data.image} className={'adImage'} /></td>
        <td>{data.type === 'big_ad' ? 'Большой' : 'Маленький'}</td>
        <td>{data.adActions}</td>
        <td>
            <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" disabled={true} defaultChecked={data.isShow} label="Показывать" onChange={(e) => {
                    setIsShow(e.target.checked);
                }}/>
            </Form.Group>
        </td>
        <td>
            <Button className={'text-right mr-2'} variant="outline-primary" size={'sm'} onClick={() => {
                setShow(true);
                getStats();
            }}>
                <FontAwesomeIcon icon={'info-circle'}/>
            </Button>
            <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                setIsEdit(true);
            }}>
                <FontAwesomeIcon icon={'pen'}/>
            </Button>
            <Button className={'text-right'} variant="danger" size={'sm'}
                    onClick={() => props.delete(props.num)}>
                <FontAwesomeIcon icon={'trash'}/>
            </Button>

            <Modal show={show} onHide={() => setShow(false)}>
                {data && <Modal.Body>
                    <img src={Close} alt={'close'} onClick={() => setShow(false)} style={{
                        float: 'right',
                        width: 33,
                        height: 33,
                    }}/>
                    <p style={{
                        fontSize: 22,
                        textAlign: 'center',
                        color: '#000000',
                        marginTop: 33,
                        marginBottom: 29,
                    }}>Объявление №{data.id}</p>
                    <Container fluid="sm">
                        <Row>
                            <Col>
                                <img alt={'notice_' + data.id} src={serverAdUrl + data.image} className={'modalFlowerImagePreview'} />
                            </Col>
                            <Col md="8">
                                <Table striped bordered hover size="sm">
                                    <thead>
                                    <tr>
                                        <th>Месяц</th>
                                        <th>Кол-во просмотров</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {stats && stats.length > 0 ? stats.map((item, key) => {
                                        return <tr key={key}>
                                            <td className={'text-center'}>{months[item.month - 1].name} {item.year}</td>
                                            <td className={'text-center'}>{item.count}</td>
                                        </tr>;
                                    }) : <tr>
                                        <td className={'text-center'} colSpan={2}>Данных нет.</td>
                                    </tr>}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>}
            </Modal>
        </td>
    </tr>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(AdminAdTr)