import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";
import Close from '../images/modal_close.webp';

const DiscountModal = function (props) {
    const [show, setShow] = useState(false);
    const [code, setCode] = useState('');

    useEffect(() => {
        if (props.count > 0) setShow(true);
    }, [props.count]);

    return <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
            <img src={Close} alt={'close'} onClick={() => {
                setShow(false);
                setCode('');
            }} style={{
                float: 'right',
                width: 33,
                height: 33,
            }}/>
            <p style={{
                fontSize: 22,
                textAlign: 'center',
                color: '#000000',
                marginTop: 33,
                marginBottom: 29,
            }}>Код для получения скидки</p>
            <div className={'formRow'} style={{
                maxWidth: 337,
            }}>
                <p className={'formFieldName text-right'} style={{
                    width: 161,
                }}>Скидочный код</p>
                <input className={'formField'} value={code} onChange={(text) => {
                    setCode(text.target.value);
                }}/>
            </div>
            <div className={'d-flex justify-content-between align-items-center'}>
                <button className={'FormBtn'} onClick={() => {
                    props.onSubmit(code);
                }}>Перейти к оплате
                </button>
                <p className={'pointer mb-0'} onClick={() => props.onSubmit(null)}>или продолжить без кода</p>
            </div>
        </Modal.Body>
    </Modal>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(DiscountModal)