import {Breadcrumb, Table, Button, Form, Col} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {useEffect, useState, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../tools/ajaxManager";

const AdminSettings = function (props) {
    // state
    let auth = useAuth();
    const [settings, setSettings] = useState([])
    const [loadKey, setLoadKey] = useState(null);

    // effects
    useEffect(() => {
        if (auth.token) request(
            '/setting/',
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                setSettings(response.data);
            },
            (error) => {
            },
        );
    }, [auth.token]);

    // functions
    function handleSave(e, key) {
        e.preventDefault();
        setLoadKey(key);

        let data = new FormData();
        data.append('code', settings[key].code);
        data.append('value', settings[key].value);
        if (auth.token) request(
            '/setting/',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...settings];
                tmp[key] = response.data;
                setSettings(tmp);
                setLoadKey(null);
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }

    return <div className={'content'}>
        <h3>Настройки</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список работ по импорту</Breadcrumb.Item>
        </Breadcrumb>

        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>Настройка</th>
                    <th>Значение</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {settings.map((item, key) => {
                    return <tr key={key}>
                        <td>{item.name}</td>
                        <td>
                            <input className={'form-control'} value={item.value} onChange={(e) => {
                                let tmp = [...settings];
                                tmp[key].value = e.target.value;
                                setSettings(tmp);
                            }} />
                        </td>
                        <td>
                            <Button disabled={loadKey === key} className={'text-right'} variant="success" size={'sm'}
                                    onClick={(e) => handleSave(e, key)}>
                                <FontAwesomeIcon icon={loadKey === key ? 'spinner' : 'check'}/>
                            </Button>
                        </td>
                    </tr>;
                })}
                </tbody>
            </Table>
        </div>
    </div>;
}

export default AdminSettings;