import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";
import Close from '../images/modal_close.webp';
import {useHistory} from "react-router-dom";

const DuplicateModal = function (props) {
    const [show, setShow] = useState(false);
    const [go, setGo] = useState(props.go);
    const history = useHistory();

    useEffect(() => {
        if (props.go !== null) {
            setShow(true);
            setGo(props.go);
        }
    }, [props]);

    return <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
            <img src={Close} alt={'close'} onClick={() => {
                setShow(false);
            }} style={{
                float: 'right',
                width: 33,
                height: 33,
            }}/>
            <p style={{
                fontSize: 22,
                textAlign: 'center',
                color: '#000000',
                marginTop: 33,
                marginBottom: 29,
            }}>Копия создана</p>
            <div className={'d-flex justify-content-center align-items-center'}>
                <button className={'FormBtn'} onClick={() => {
                    if (go) {
                        window.open('/flowerbed/' + go, '_self');
                    }
                }}>Перейти к новой клумбе
                </button>
            </div>
        </Modal.Body>
    </Modal>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(DuplicateModal)