import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {months} from '../tools/data'

const MonthColors = function (props) {
    const [data, setData] = useState(props.data);
    const [activeColor, setActiveColor] = useState(props.activeColor);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    useEffect(() => {
        setActiveColor(props.activeColor);
    }, [props.activeColor]);

    return <div className={'monthColorsBlock'}>
        {data.monthColors.map((color, key) => {
            let width = props.width ?? 20
            return <div key={key} style={{
                border: 'solid ' + (((data.minMonth <= data.maxMonth &&
                    key + 1 >= data.minMonth && key + 1 <= data.maxMonth) ||
                    (data.minMonth > data.maxMonth && ((key + 1 <= data.minMonth) ||
                        (key + 1 >= data.maxMonth)))) ? '2' : '1') + 'px #82B21E',
                backgroundColor: ((data.minMonth <= data.maxMonth &&
                    key + 1 >= data.minMonth && key + 1 <= data.maxMonth) ||
                    (data.minMonth > data.maxMonth && ((key + 1 <= data.minMonth) ||
                        (key + 1 >= data.maxMonth)))) ?
                    activeColor : color,
                marginLeft: -1,
                width: (((data.minMonth <= data.maxMonth &&
                    key + 1 >= data.minMonth && key + 1 <= data.maxMonth) ||
                    (data.minMonth > data.maxMonth && ((key + 1 <= data.minMonth) ||
                        (key + 1 >= data.maxMonth)))) ? width + 2 : width),
                height: 20,
                borderBottomLeftRadius: key === 0 ? 5 : 0,
                borderTopLeftRadius: key === 0 ? 5 : 0,
                borderTopRightRadius: key === 11 ? 5 : 0,
                borderBottomRightRadius: key === 11 ? 5 : 0,
            }}>
                <p className={'romeMonth'}>{months[key].rome}</p>
            </div>;
        })}
    </div>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(MonthColors)