import React from "react";
import {
    Route,
    Redirect,
} from "react-router-dom";

import {useAuth} from "../../context/provideAuth";

const PrivateRoute = function({ children, ...rest }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute;