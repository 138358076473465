import { useState, useEffect } from "react";

const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });
    const [scrollPosition, setScrollPosition] = useState({ x: null, y: null });

    const updateMousePosition = ev => {
        setMousePosition({ x: ev.pageX, y: ev.pageY });
    };
    const onScroll = e => {
        setScrollPosition({ x: null, y: e.target.scrollTop });
    };

    useEffect(() => {
        document.querySelector('.App').addEventListener("scroll", onScroll);
        window.addEventListener("mousemove", updateMousePosition);

        return () => {
            window.removeEventListener("mousemove", updateMousePosition);
            window.removeEventListener("scroll", onScroll);
        }
    }, []);

    return {x: mousePosition.x, y: mousePosition.y + scrollPosition.y};
};

export default useMousePosition;