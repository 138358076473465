import {connect} from "react-redux";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {createRef, useEffect, useState} from "react";
import request from "../tools/ajaxManager";
import {useAuth} from "../context/provideAuth";

const AdminColorTr = function (props) {
    let auth = useAuth();
    const [data, setData] = useState(props.data);
    const [isEdit, setIsEdit] = useState(false);
    const [title, setTitle] = useState(props.data.name);
    const [code, setCode] = useState(props.data.code);

    useEffect(() => {
        setData(props.data);
    }, [props.data])


    function handleEdit() {
        if (auth.token) {
            let formData = {
                name: title,
                code: code,
            };

            request(
                '/color/' + data.id,
                'PUT',
                formData,
                {
                    "YT-AUTH-TOKEN": "YourTar " + auth.token,
                },
                (response) => {
                    setIsEdit(false);
                    setData(response.data);
                },
                (error) => {
                    alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
                },
            );
        }
    }

    if (isEdit) {
        return <tr>
            <td>{props.num + 1}</td>
            <td>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Название
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setTitle(e.target.value)}
                                      type="text" defaultValue={data.name} />
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Form.Group controlId="desc">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Код
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setCode(e.target.value)}
                                      type="text" defaultValue={data.code} />
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                    setIsEdit(false);
                    setTitle(data.name);
                    setCode(data.code);
                }}>
                    <FontAwesomeIcon icon={'undo'} className={'mr-2'}/>
                    Отмена
                </Button>
                <Button className={'text-right'} variant="success" size={'sm'}
                        onClick={handleEdit}>
                    <FontAwesomeIcon icon={'check'} className={'mr-2'}/>
                    Сохранить
                </Button>
            </td>
        </tr>
    }

    return <tr >
        <td>{props.num + 1}</td>
        <td>{data.name}</td>
        <td>
            <span style={{
                width: 20,
                height: 20,
                borderRadius: 10,
                border: 'solid 1px black',
                backgroundColor: data.code,
                display: 'inline-block',
                marginRight: 15,
            }} />
            {data.code}
        </td>
        <td>
            <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                setIsEdit(true);
            }}>
                <FontAwesomeIcon icon={'pen'}/>
            </Button>
            <Button className={'text-right'} variant="danger" size={'sm'}
                    onClick={() => props.delete(props.num)}>
                <FontAwesomeIcon icon={'trash'}/>
            </Button>
        </td>
    </tr>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(AdminColorTr)