import {connect} from "react-redux";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";

const AdminLessonCategoryTr = function (props) {
    const [data, setData] = useState(props.data);
    const [isEdit, setIsEdit] = useState(false);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    if (isEdit) {
        return <tr>
            <td>{props.num + 1}</td>
            <td>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Название
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setTitle(e.target.value)}
                                      type="text" defaultValue={data.name} />
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                    setIsEdit(false);
                    setTitle(data.title);
                }}>
                    <FontAwesomeIcon icon={'undo'} className={'mr-2'}/>
                    Отмена
                </Button>
                <Button className={'text-right'} variant="success" size={'sm'}
                        onClick={() => {
                            let formData = new FormData();
                            formData.append('id', data.id);
                            if (data.title !== title && title.length > 0)
                                formData.append('name', title);
                            props.edit(formData, props.num);
                            setIsEdit(false);
                        }}>
                    <FontAwesomeIcon icon={'check'} className={'mr-2'}/>
                    Сохранить
                </Button>
            </td>
        </tr>
    }

    return <tr >
        <td>{props.num + 1}</td>
        <td style={{maxWidth: 200, wordWrap: 'break-word'}}>{data.name}</td>
        <td>
            <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                setIsEdit(true);
            }}>
                <FontAwesomeIcon icon={'pen'}/>
            </Button>
            <Button className={'text-right'} variant="danger" size={'sm'}
                    onClick={() => props.delete(props.num)}>
                <FontAwesomeIcon icon={'trash'}/>
            </Button>
        </td>
    </tr>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(AdminLessonCategoryTr)
