import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useAuth} from "../context/provideAuth";
import request from "../tools/ajaxManager";
import {Link} from "react-router-dom";

const NoteListItem = function (props) {
    const auth = useAuth();

    const handleDelete = (e) => {
        e.preventDefault();
        if (auth.token) {
            request(
                '/note/' + props.data.id,
                'DELETE',
                {},
                {
                    'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
                },
                async (response) => {
                    props.remove(props.key);
                },
                (err) => {
                    console.log('ERROR', err)
                }
            )
        }
    }

    return <Link to={{
        pathname: '/service/note/edit',
        state: {
            data: props.data,
        }
    }}>
        <div className={'noteItem pointer mt-4'}>
            <p>{props.data.title}</p>
            <FontAwesomeIcon onClick={handleDelete} icon={'trash'} color={'#82B21E'} size={'1x'}/>
        </div>
    </Link>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(NoteListItem)