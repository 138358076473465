import {Button} from 'react-bootstrap';
import React, {useState} from "react";
import request from "../../tools/ajaxManager";
import {connect} from "react-redux";
import SocialBlock from "../socialBlock";
import {Link} from "react-router-dom";

const Login = function (props) {
    //state
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [error, setError] = useState(null);

    //functions
    function handleLogin(e, socEmail = null, socPass = null) {
        if (e) e.preventDefault();
        let data = new FormData();
        data.append('email', socEmail !== null ? socEmail : email);
        data.append('pass', socPass !== null ? socPass : pass);

        request(
            '/security/login',
            'POST',
            data,
            {},
            (response) => {
                setError(null);
                props.onLogin(response);
            },
            (error) => {
                if (error.message) {
                    setError(error.message);
                }
            },
        );
    };

    return <div className={'loginForm'}>
        <form onSubmit={handleLogin}>
            <input onChange={(e) => setEmail(e.target.value)}
                          type="email" placeholder="Адрес электронной почты"/>
            <input onChange={(e) => setPass(e.target.value)}
                          type="password" placeholder="Пароль"/>

            {error && <p className={'alert alert-danger'} role={'alert'}>{error}</p>}
            <Button onClick={handleLogin} variant="custom" type="submit">
                Войти
            </Button>

            <Link to={'/password/reset'}><p className={'text-center mt-3 blackColor'}>Забыли пароль?</p></Link>

            <SocialBlock onSubmit={async (tmpEmail, pass1, confirm) => {
                handleLogin(null, tmpEmail, pass1)
            }} />
        </form>
    </div>;
}

export default connect((state /*, ownProps*/) => {
    return {
        user: state,
    }
}, dispatch => ({
    onLogin: (data) => {
        dispatch({type: 'LOGIN', payload: data})
    },
}))(Login);