import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import Login from "../../components/forms/login";
import Register from "../../components/forms/register";

const Auth = function (props) {
    return <div className={'indexPage'}>
        <h3>Личный кабинет</h3>
        <div className={'card1'}>
            <div>
                <Link to={'/login'}>
                    <div className={'card1Tab'}>
                    <span className={props.location.pathname === '/login' ? 'active' : ''}>Вход</span>
                </div>
                </Link>
                <Link to={'/register'}>
                <div className={'card1Tab'}>
                    <span className={props.location.pathname === '/register' ? 'active' : ''}>Регистрация</span>
                </div>
                </Link>
            </div>
            <div className={'divider'} />
            <Switch>
                <Route exact path="/register">
                    <Register />
                </Route>
                <Route exact path="/login">
                    <Login />
                </Route>
            </Switch>
        </div>
    </div>;
}

export default withRouter(connect((state /*, ownProps*/) => {
    return state;
}, dispatch => ({}))(Auth));