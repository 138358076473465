import {
    Link,
    useLocation
} from "react-router-dom";

const NoMatch = function () {
    let location = useLocation();

    return (
        <div>
            <h3>
                404: Страницы <code>{location.pathname}</code> не существует
            </h3>
            <Link to={'/'}>Вернуться на главную</Link>
        </div>
    );
}

export default NoMatch;