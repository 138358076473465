import {Route, Switch} from "react-router-dom";
import NoMatch from "../pages/NoMatch";
import Header from "../components/header";
import AdminMenu from "../pages/admin/menu";
import AdminUsers from "../pages/admin/users";
import AdminFlowers from "../pages/admin/flowers";
import AdminFlowerForm from "../pages/admin/flowerForm";
import AdminJobList from "../pages/admin/jobs";
import AdminFeedbackList from "../pages/admin/feedbacks";
import AdminImages from "../pages/admin/images";
import AdminDelayedNotificationList from "../pages/admin/delayedNotifications";
import AdminSettings from "../pages/admin/settings";
import AdminAds from "../pages/admin/ads";
import AdminLessons from "../pages/admin/lessons";
import AdminDiscount from "../pages/admin/discounts";
import AdminColors from "../pages/admin/colors";
import AdminLessonCategories from "../pages/admin/lessonCategories";

const AdminLayout =  function(props) {
    return <div>
        <Header />
        <Switch>
            <Route exact path="/admin">
                <AdminMenu />
            </Route>
            <Route exact path="/admin/users">
                <AdminUsers />
            </Route>
            <Route exact path="/admin/jobs">
                <AdminJobList />
            </Route>
            <Route exact path="/admin/feedbacks">
                <AdminFeedbackList />
            </Route>
            <Route exact path="/admin/flowers">
                <AdminFlowers />
            </Route>
            <Route exact path="/admin/images">
                <AdminImages />
            </Route>
            <Route exact path="/admin/flowers/add">
                <AdminFlowerForm />
            </Route>
            <Route exact path="/admin/flowers/edit">
                <AdminFlowerForm />
            </Route>
            <Route exact path="/admin/notifications">
                <AdminDelayedNotificationList />
            </Route>
            <Route exact path="/admin/settings">
                <AdminSettings />
            </Route>
            <Route exact path="/admin/partner/notice">
                <AdminAds />
            </Route>
            <Route exact path="/admin/lessons">
                <AdminLessons />
            </Route>
            <Route exact path="/admin/lessons/category">
                <AdminLessonCategories />
            </Route>
            <Route exact path="/admin/colors">
                <AdminColors />
            </Route>
            <Route exact path="/admin/discount">
                <AdminDiscount />
            </Route>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    </div>;
}

export default AdminLayout;
