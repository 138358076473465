import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FacebookAuth from 'react-facebook-auth';

const SocialBlock = function (props) {
    const vkAuth = () => {
        window.VK.Auth.login((r) => {
            props.onSubmit(
                r.session.user.id + '@vk.com',
                r.session.user.id + '_' + r.session.user.id.split("").reverse().join(""),
                r.session.user.id + '_' + r.session.user.id.split("").reverse().join("")
            )
        }, 4);
    };
    const fbAuth = (response) => {
        props.onSubmit(
            response.id + '@fb.com',
            response.id + '_' + response.id.split("").reverse().join(""),
            response.id + '_' + response.id.split("").reverse().join("")
        )
    };

    return <div className={'socialBlockAuth'}>
        <span><FontAwesomeIcon icon={['fab', 'vk']} onClick={vkAuth}/></span>
        <FacebookAuth
            appId="258418606039607"
            callback={fbAuth}
            component={MyFacebookButton}
        />
    </div>;
}

export default SocialBlock;

const MyFacebookButton = ({ onClick }) => (
    <span><FontAwesomeIcon icon={['fab', 'facebook-f']} onClick={onClick}/></span>
);
