import React, { useContext, createContext, useState } from "react";
import {connect} from "react-redux";

const authContext = createContext({});

const ProvideAuth = function(props) {
    const auth = useProvideAuth();

    if (JSON.stringify(auth.user) !== JSON.stringify(props.user.user) ||
        auth.token !== props.user.token) {
        auth.login(props.user);
    }

    return (
        <authContext.Provider value={auth}>
            {props.children}
        </authContext.Provider>
    );
}

const useAuth = function() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);

    const login = data => {
        setUser(data.user);
        setToken(data.token);
    };

    const logout = () => {
        setUser(null);
        setToken(null);
    };

    return {
        user,
        token,
        login,
        logout,
    };
}

export default connect((state /*, ownProps*/) => {
    return {
        user: state.user,
    }
}, {})(ProvideAuth);
export {useAuth};