import React, {useState} from "react";
import AdBlock from "../../components/adBlock";
import {useHistory} from 'react-router-dom';
import {Button} from "react-bootstrap";
import {useAuth} from "../../context/provideAuth";
import request from "../../tools/ajaxManager";

const PasswordChangeScreen = function (props) {
    const [current, setCurrent] = useState('');
    const [newPass, setNewPass] = useState('');
    const [repeat, setRepeat] = useState('');
    const [error, setError] = useState('');
    const auth = useAuth();
    let history = useHistory();

    const handleSend = (e) => {
        e.preventDefault();

        if (newPass !== repeat) {
            setError('Пароли не совпадают!');
            return;
        }

        let data = new FormData();
        data.append('current_pass', current);
        data.append('new_pass', newPass);
        data.append('confirm_pass', repeat);

        request(
            '/security/password/change',
            'POST',
            data,
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {
                history.push('/profile');
            },
            (err) => {
                console.log('ERROR', err)
            }
        );
    }

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <div className={'card1'}>
                    <div>
                        <div className={'card1Tab'}>
                            <span>Смена пароля</span>
                        </div>
                    </div>
                    <div className={'divider'}/>
                    <div className={'loginForm'}>
                        <form onSubmit={handleSend}>
                                <input onChange={(e) => setCurrent(e.target.value)}
                                       type="password" required={true}
                                       placeholder="Старый пароль"/>
                                <input onChange={(e) => setNewPass(e.target.value)}
                                       type="password" required={true}
                                       placeholder="Новый пароль"/>
                                <input onChange={(e) => setRepeat(e.target.value)}
                                       type="password" required={true}
                                       placeholder="Повторите пароль"/>

                            <Button onClick={handleSend} variant="custom" type="submit">
                                Изменить пароль
                            </Button>
                            {error && <p className={'alert alert-danger'} role={'alert'}>{error}</p>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default PasswordChangeScreen;