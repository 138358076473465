import {Button} from 'react-bootstrap';
import {useState} from "react";

import request from "../../tools/ajaxManager";
import {connect} from "react-redux";
import SocialBlock from "../socialBlock";
import {Link} from "react-router-dom";

const Register = function (props) {
    //state
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [pass2, setPass2] = useState('');
    const [error, setError] = useState(null);

    //functions
    function handleReg(e, socEmail = null, socPass = null, socPass2 = null) {
        if (e) e.preventDefault();
        let data = new FormData();
        data.append('email', socEmail !== null ? socEmail : email);
        data.append('pass', socPass !== null ? socPass : pass);
        data.append('confirm_pass', socPass2 !== null ? socPass2 : pass2);

        request(
            '/security/register',
            'POST',
            data,
            {},
            (response) => {
                props.onLogin(response);
                setError(null);
            },
            (error) => {
                if (error.message) {
                    setError(error.message);
                }
            },
        );
    };

    return <div className={'loginForm'}>
        <form onSubmit={handleReg}>
            <input onChange={(e) => setEmail(e.target.value)}
                   type="email" placeholder="Адрес электронной почты"/>
            <input onChange={(e) => setPass(e.target.value)}
                   type="password" placeholder="Пароль"/>
            <input onChange={(e) => setPass2(e.target.value)}
                   type="password" placeholder="Повторите пароль"/>
            {error && <p className={'alert alert-danger'} role={'alert'}>{error}</p>}
            <Button onClick={handleReg} variant="custom" type="submit">
                Зарегистрироваться
            </Button>
        </form>
        <SocialBlock onSubmit={async (tmpEmail, pass1, confirm) => {
            handleReg(null, tmpEmail, pass1, confirm)
        }} />
        <p className={'small mt-3 text-center'}>Регистрируясь, Вы соглашаетесь с <Link to={'/policy'}>правилами использования</Link> сервиса.</p>
        <p className={'small mt-3 text-center'}>Регистрируясь, Вы получаете 5 дней тестового периода в подарок!</p>
    </div>;
}

export default connect((state /*, ownProps*/) => {
    return {
        user: state,
    }
}, dispatch => ({
    onLogin: (data) => {
        dispatch({type: 'LOGIN', payload: data})
    },
}))(Register);