import {Nav, Card, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AdminMenu = function (props) {
    return <div className={'content'}>
        <h3>Административная панель</h3>
        <div>
            <Row>
                <Col>
                    <Card style={{ width: '18rem', margin: '0 auto 20px auto', }}>
                        <Card.Header as="h5">
                            <FontAwesomeIcon icon={'cannabis'} className={'mr-2'}/>
                            Растения
                        </Card.Header>
                        <Card.Body>
                            <Nav className="flex-column">
                                <Nav.Link href="/admin/flowers">Список растений</Nav.Link>
                                <Nav.Link href="/admin/images">Список фотографий растений</Nav.Link>
                                <Nav.Link href="/admin/jobs">Импорт растений</Nav.Link>
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{ width: '18rem', margin: '0 auto 20px auto', }}>
                        <Card.Header as="h5">
                            <FontAwesomeIcon icon={'users'} className={'mr-2'}/>
                            Пользователи
                        </Card.Header>
                        <Card.Body>
                            <Nav className="flex-column">
                                <Nav.Link href="/admin/users">Список пользователей</Nav.Link>
                                <Nav.Link href="/admin/feedbacks">Список заявок на обратную связь</Nav.Link>
                                <Nav.Link href="/admin/notifications">Список отложенных уведомлений</Nav.Link>
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{ width: '18rem', margin: '0 auto 20px auto', }}>
                        <Card.Header as="h5">
                            <FontAwesomeIcon icon={'hand-holding-usd'} className={'mr-2'}/>
                            Каналы доходов
                        </Card.Header>
                        <Card.Body>
                            <Nav className="flex-column">
                                <Nav.Link href="/admin/partner/notice">Реклама</Nav.Link>
                                <Nav.Link href="/admin/lessons/category">Категории уроков</Nav.Link>
                                <Nav.Link href="/admin/lessons">Уроки</Nav.Link>
                                <Nav.Link href="/admin/discount">Скидки</Nav.Link>
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{ width: '18rem', margin: '0 auto 20px auto', }}>
                        <Card.Header as="h5">
                            <FontAwesomeIcon icon={'cog'} className={'mr-2'}/>
                            Настройки
                        </Card.Header>
                        <Card.Body>
                            <Nav className="flex-column">
                                <Nav.Link href="/admin/colors">Цвета</Nav.Link>
                                <Nav.Link href="/admin/settings">Настройки</Nav.Link>
                            </Nav>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    </div>;
}

export default AdminMenu;
