import {Breadcrumb, Table, Button, Badge} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import request from "../../tools/ajaxManager";
import CustomPagination from "../../components/customPagination";
import {months} from "../../tools/data";

const AdminFlowers = function (props) {
    // state
    let auth = useAuth();
    const history = useHistory();
    let search = history.location.search && history.location.search.length > 0 ? history.location.search.substring(1) : '';
    let defaultPage = search && search.length > 0
        ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {}
    const [flowers, setFlowers] = useState([]);
    const [page, setPage] = useState(defaultPage ? (defaultPage.page ? defaultPage.page : 1) : 1);
    const [count, setCount] = useState(0);

    // effects
    useEffect(() => {
        if (auth.token) request(
            '/flower/?offset=' + ((page - 1) * 30) + '&countNeed=1',
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => { console.log(response)
                setFlowers(response.data);
                setCount(response.count);
            },
            (error) => {
                console.log('err', error)
            },
        );
    }, [auth.token, page]);

    // functions
    function handleDelete(key) {
        if (auth.token) request(
            '/flower/' + flowers[key].id,
            'DELETE',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...flowers];
                tmp.splice(key, 1);
                setFlowers(tmp);
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }

    return <div className={'content'}>
        <h3>Список растений</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список растений</Breadcrumb.Item>
        </Breadcrumb>

        <div className={'text-right'}>
            <Button className={'text-right'} variant="light" href={'/admin/flowers/add'}>
                <FontAwesomeIcon icon={'plus-square'}/> Добавить растение
            </Button>
        </div>

        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Название</th>
                    <th>Освещение</th>
                    <th>Высота в пер.<br/>цветения</th>
                    <th>Пора<br/>цветения</th>
                    <th>Окрас<br/>цветения</th>
                    <th>Цвета по<br/>месяцам</th>
                    <th>Зона<br/>морозост.</th>
                    <th>Диаметр</th>
                    <th>Замечания</th>
                    <th>Почва</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {flowers.map((item, key) => {
                    let sun = '', bloom;
                    if (item.isSun) sun += 'Солнце';
                    if (item.isPenumbra) sun += (sun.length > 0 ? '/' : '') + 'Полутень';
                    if (item.isShadow) sun += (sun.length > 0 ? '/' : '') + 'Тень';
                    if (typeof item.bloomColors === 'object' && item.bloomColors !== null)
                        bloom = Object.values(item.bloomColors);
                    else bloom = item.bloomColors;
                    return <tr key={key}>
                        <td>{((page - 1) * 30) + (key + 1)}</td>
                        <td>{item.name}<br/>({item.latName})</td>
                        <td>{sun}</td>
                        <td>{item.minHeight} - {item.maxHeight} см</td>
                        <td>{months[item.minMonth - 1].name} - {months[item.maxMonth - 1].name}</td>
                        <td>{bloom.map((color, key) => {
                            if (color.length === 7)
                                return <div key={key} className={'colorCircle'}
                                            style={{backgroundColor: color ?? '#000000'}}></div>;
                            else return null;
                        })}</td>
                        <td>{item.monthColors.map((color, key) => {
                            if ((key + 1) < months[item.minMonth - 1].number || (key + 1) > months[item.maxMonth - 1].number)
                                return <Badge key={key} variant="light" style={{backgroundColor: color ?? '#000000'}}>
                                    {months[key].short}
                                </Badge>;
                            else return <Badge key={key} variant="light">
                                {months[key].short}: ц
                            </Badge>;
                        })}</td>
                        <td>{item.frostResistanceMin !== item.frostResistanceMax
                            ? item.frostResistanceMin + '-' + item.frostResistanceMax : item.frostResistanceMin}</td>
                        <td>{item.diameter} см</td>
                        <td>{item.ground}</td>
                        <td>{item.humidity}</td>
                        <td>
                            <Button className={'text-right'} variant="warning" size={'sm'} onClick={() => {
                                history.push('/admin/flowers/edit', {
                                    object: item,
                                    page: page,
                                })
                            }}>
                                <FontAwesomeIcon icon={'pen'}/>
                            </Button>
                            <Button className={'text-right'} variant="danger" size={'sm'}
                                    onClick={() => handleDelete(key)}>
                                <FontAwesomeIcon icon={'trash'}/>
                            </Button>
                        </td>
                    </tr>;
                })}
                </tbody>
            </Table>
        </div>

        <CustomPagination update={setPage} currentPage={page} count={Math.ceil(count / 30)}/>
    </div>;
}

export default AdminFlowers;
