import React, {useEffect, useState} from "react";
import AdBlock from "../../components/adBlock";
import {withRouter, Link} from 'react-router-dom';
import {useAuth} from "../../context/provideAuth";
import request from "../../tools/ajaxManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {serverImageUrl} from "../../tools/parameters";

const FlowerbedList = function (props) {
    const auth = useAuth();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (auth.token)
            request(
                '/flowerbed/',
                'GET',
                {},
                {
                    'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
                },
                async (response) => {
                    setData(response.data)
                },
                (err) => {
                    console.log('ERROR', err)
                }
            )
    }, [auth.token])

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <h3>Мои проекты</h3>
                <div className={'services'}>
                    <Link to={'/flowerbed/create'} className={'projectLink create'}>
                            <FontAwesomeIcon icon={'plus'} color={'black'} size={'3x'}/>
                            <span>Создать</span>
                    </Link>
                    {data && data.map((item, key) => {
                        return <Link key={key} to={'/flowerbed/' + item.id} className={'projectLink'}>
                            {item.preview ?
                                <img alt={'Preview'} src={serverImageUrl + item.preview} /> :
                                <div className={'imagePlaceholder'}></div>
                            }
                            <p>{item.name}</p>
                        </Link>;
                    })}
                </div>
            </div>
        </div>
    </div>;
}

export default withRouter(FlowerbedList);