import React, {useEffect, useState} from "react";
import AdBlock from "../../components/adBlock";
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Button} from "react-bootstrap";
import {useAuth} from "../../context/provideAuth";
import request from "../../tools/ajaxManager";
import NoteListItem from "../../components/NoteListItem";
import SocialBlock from "../../components/socialBlock";

const NoteScreen = function (props) {
    const auth = useAuth();
    let location = useLocation();
    const history = useHistory();
    const [title, setTitle] = useState(location.state.data ? location.state.data.title : '');
    const [content, setContent] = useState(location.state.data ? location.state.data.content : '');
    const [data, setData] = useState(location.state.data ? location.state.data : null);
    const [isNew, setIsNew] = useState(location.pathname === '/service/note/create');

    useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(props.data)) {
            if (location.pathname === '/service/note/create') {
                setData(null);
                setContent('');
                setTitle('');
                setIsNew(true);
            } else {
                setData(location.state.data);
                setContent(location.state.data.content);
                setTitle(location.state.data.title);
                setIsNew(false);
            }
        }
    }, [props.data]);

    const handleSave = (e) => {
        e.preventDefault();

        if (auth.token) {
            let formData = new FormData();
            formData.append('content', content);
            if (isNew) {
                formData.append('title', title);
            }
            else formData.append('id', data.id);

            request(
                '/note/',
                'POST',
                formData,
                {
                    'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
                },
                async (response) => {
                    history.push('/service/note')
                },
                (err) => {
                    console.log('ERROR', err)
                }
            )
        }
    }

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <div className={'card1'}>
                    <div>
                        <div className={'card1Tab'}>
                            <span>{!isNew ? title : 'Новая заметка'}</span>
                        </div>
                    </div>
                    <div className={'divider'}/>
                    <div className={'loginForm'}>
                        <form onSubmit={handleSave}>
                            {isNew && <input onChange={(e) => setTitle(e.target.value)}
                                   placeholder="Заголовок" defaultValue={title}/>}
                            <textarea onChange={(e) => setContent(e.target.value)}
                                   placeholder="Текст заметки" defaultValue={content} />

                            <Button onClick={handleSave} variant="custom" type="submit">
                                Сохранить
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default NoteScreen;