import {connect} from "react-redux";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {createRef, useEffect, useState} from "react";

const AdminLessonTr = function (props) {
    const [data, setData] = useState(props.data);
    const [isEdit, setIsEdit] = useState(false);
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [link, setLink] = useState('');

    const filesRef = createRef();

    useEffect(() => {
        setData(props.data);
    }, [props.data])
    useEffect(() => {
        setCategories(props.categories);
    }, [props.categories])

    if (isEdit) {
        return <tr>
            <td>{props.num + 1}</td>
            <td>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Название
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setTitle(e.target.value)}
                                      type="text" defaultValue={data.title} />
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Form.Group controlId="desc">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Описание
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setDescription(e.target.value)}
                                      type="text" as="textarea" rows={3} defaultValue={data.description} />
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Категория
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" custom defaultValue={data.category ? data.category.id : null}
                                      onChange={(e) => setCategory(e.target.value)}>
                            <option value={null}>Не выбрана</option>
                            {categories.map((cat, key) => {
                                return <option key={key} value={cat.id}>{cat.name}</option>;
                            })}
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Form.Group controlId="res">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Ссылка
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setLink(e.target.value)}
                                      type="text" defaultValue={data.link} />
                    </InputGroup>
                </Form.Group>
                <p>или</p>
                <Form.Control placeholder="Выберите файл" type={'file'} ref={filesRef} />
            </td>
            <td>
                <Form.Group controlId="price">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Цена
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setPrice(e.target.value)}
                                      type="number" as="input" defaultValue={data.price} />
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                    setIsEdit(false);
                    setLink(data.link);
                    setTitle(data.title);
                    setDescription(data.description);
                }}>
                    <FontAwesomeIcon icon={'undo'} className={'mr-2'}/>
                    Отмена
                </Button>
                <Button className={'text-right'} variant="success" size={'sm'}
                        onClick={() => {
                            let formData = new FormData();
                            formData.append('id', data.id);
                            if (data.link !== link)
                                formData.append('link', link.length > 0 ? link : null);
                            if (data.title !== title && title.length > 0)
                                formData.append('title', title);
                            if (data.price !== price && price.length > 0)
                                formData.append('price', price);
                            if (data.description !== description && description.length > 0)
                                formData.append('description', description);
                            if (filesRef.current.files.length > 0)
                                formData.append('file', filesRef.current.files[0]);
                            props.edit(formData, props.num);
                            setIsEdit(false);
                        }}>
                    <FontAwesomeIcon icon={'check'} className={'mr-2'}/>
                    Сохранить
                </Button>
            </td>
        </tr>
    }

    return <tr >
        <td>{props.num + 1}</td>
        <td style={{maxWidth: 200, wordWrap: 'break-word'}}>{data.title}</td>
        <td style={{maxWidth: 200, wordWrap: 'break-word'}}>{data.description}</td>
        <td style={{maxWidth: 200, wordWrap: 'break-word'}}>{data.category ? data.category.name : 'Не назначена'}</td>
        <td>{data.type === 'youtube' ? 'YouTube' : 'Свой'}</td>
        <td>{data.price} рублей</td>
        <td>
            <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                setIsEdit(true);
            }}>
                <FontAwesomeIcon icon={'pen'}/>
            </Button>
            <Button className={'text-right'} variant="danger" size={'sm'}
                    onClick={() => props.delete(props.num)}>
                <FontAwesomeIcon icon={'trash'}/>
            </Button>
        </td>
    </tr>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(AdminLessonTr)
