let defaultState = { user: null, token: null, };

const userReducer = function(state = defaultState, action) {
    switch (action.type) {
        case 'LOGIN':
            return action.payload;
        case 'LOGOUT':
            return defaultState;
        default:
            return state;
    }
};

export default userReducer;