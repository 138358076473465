import React, {useEffect, useState} from "react";
import AdBlock from "../../components/adBlock";
import {Link, useHistory} from 'react-router-dom';

import ProjImg from '../../images/icon_project.webp';
import VideoImg from '../../images/icon_video.webp';
import QAImg from '../../images/icon_qa.webp';
import NoteImg from '../../images/icon_note.webp';
import AboutImg from '../../images/icon_about.webp';
import TariffImg from '../../images/icon_tariff.webp';
import {Alert, Button} from "react-bootstrap";
import request from "../../tools/ajaxManager";
import {useAuth} from "../../context/provideAuth";
import {months} from "../../tools/data";
import DiscountModal from "../../components/DiscountModal";

const tariffs = [
    {
        title: 'Ежемесячный',
        price: 349,
        allPrice: 349,
        oldPrice: null,
        months: 1,
        features: [
            'Создание клумб',
            'Фильтр',
            'Список растений',
        ],
    },
    {
        title: 'Полугодовой',
        price: 298,
        allPrice: 1790,
        oldPrice: 349,
        months: 6,
        features: [
            'Создание клумб',
            'Фильтр',
            'Список растений',
        ],
    },
    {
        title: 'Годовой',
        price: 249,
        allPrice: 2990,
        oldPrice: 349,
        months: 12,
        features: [
            'Создание клумб',
            'Фильтр',
            'Список растений',
        ],
    },
];

const Tariffs = function (props) {
    let history = useHistory();
    const auth = useAuth();
    const [user, setUser] = useState(null);
    const [count, setCount] = useState(0);
    const [id, setId] = useState(null);
    const [tariffTo, setTariffTo] = useState(null);

    useEffect(() => {
        getUser();
    }, [auth.token])

    const getUser = () => {
        if (auth.token) {
            request(
                '/user/',
                'GET',
                {},
                {
                    'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
                },
                async (response) => {
                    setUser(response.user);
                    if (response.user.tariffTo) {
                        let date = new Date(response.user.tariffTo);
                        setTariffTo(date);
                    }
                },
                (err) => {
                    console.log('ERROR', err)
                }
            )
        }
    }
    const handleBuy = (code = null) => {
        if (auth.token) {
            let data = new FormData();
            data.append('tariff_id', id);
            if (code && code.length > 0) {
                data.append('code', code);
            }

            request(
                '/payment/',
                'POST',
                data,
                {
                    'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
                },
                async (response) => {
                    if (response.url)
                        window.open(response.url, '_self');
                },
                (err, code) => {
                    if (err.message) alert(err.message)
                    console.log('ERROR', err)
                }
            )
        }
    }

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <h3>Тарифы</h3>
                <div className={'services'}>
                    {tariffs.map((item, key) => {
                        return <div key={key} className={'serviceCell tariff'}>
                            <div className={'serviceContainer'}>
                                <p className={'serviceTitle'}>
                                    {item.title}<br/>
                                    <span className={'tariffPrice'}>{item.price} руб./мес.</span><br/>
                                    <span className={'tariffOldPrice ' + (item.oldPrice ? '' : 'empty')}>
                                        {item.oldPrice ? item.oldPrice + 'руб./мес.' : '_'}
                                    </span>
                                </p>
                                <p className={'serviceDescription'}>
                                    {item.features.map((feature, index) => {
                                        return <p className={'text-center'} key={index}>{feature}</p>;
                                    })}
                                    <p className={'text-center'}>Кол-во месяцев: {item.months}</p>
                                </p>
                                <Button variant={'custom'} className={'green'} onClick={() => {
                                    setId(key + 1);
                                    setCount(count + 1);
                                }}>Купить за {item.allPrice} р.</Button>
                            </div>
                        </div>
                    })}
                </div>
                {user && tariffTo && <Alert variant={'custom mt-3 mb-3'}>
                    {user.isTestTariff ? 'Тестовый тариф ' : 'Тариф '}оплачен до {tariffTo.getDate()} {months[tariffTo.getMonth()].forDate} {tariffTo.getFullYear()}
                </Alert>}
                {user && tariffTo && user.isTestTariff && <Alert variant={'custom mt-3 mb-3'}>
                    <span className={'font-weight-bold'}>Подарок:</span> Скидка 71% по промокоду "<span className={'font-weight-bold'}>начинаем</span>" на оплату первого месяца!
                </Alert>}
                <DiscountModal count={count} onSubmit={(code) => {
                    handleBuy(code)
                }} />
            </div>
        </div>
    </div>;
}

export default Tariffs;
