import React, {useEffect, useState} from "react";
import AdBlock from "../../components/adBlock";
import {Link} from 'react-router-dom';
import {useAuth} from "../../context/provideAuth";
import request from "../../tools/ajaxManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {serverLessonUrl} from '../../tools/parameters';

import LockIcon from '../../images/icon_lock.webp';
import UnlockIcon from '../../images/icon_unlock.webp';

const LessonListScreen = function (props) {
    const [lessons, setLessons] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchTitle, setSearchTitle] = useState('');
    const [searchCategory, setSearchCategory] = useState(null);
    const auth = useAuth();

    useEffect(() => {
        getLessons();
        if (auth.token) {
            request(
                '/lesson/category/',
                'GET',
                {},
                {
                    "YT-AUTH-TOKEN": "YourTar " + auth.token,
                },
                (response) => {
                    setCategories(response.data);
                },
                (error) => {
                },
            );
        }
    }, [auth.token]);

    const getLessons = (title = null, cat = null) => {
        if (auth.token) {
            request(
                '/lesson/' + (cat ? '?category=' + cat : '') + (title && title.length > 0 ? '?title=' + title : ''),
                'GET',
                {},
                {
                    'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
                },
                async (response) => {
                    setLessons(response.data);
                },
                (err) => {
                    console.log('ERROR', err)
                }
            )
        }
    }
    const handleBuy = (lesson) => {
        if (auth.token) {
            let data = new FormData();
            data.append('lesson_id', lesson.id);

            request(
                '/payment/',
                'POST',
                data,
                {
                    'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
                },
                async (response) => {
                    window.open(response.url, '_self');
                },
                (err) => {
                    console.log('ERROR', err)
                }
            )
        }
    }

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <h3>Видеоуроки</h3>
                <div className={'inputWithIcon'}>
                    <input className={'input'} value={searchTitle} onChange={(text) => {
                        setSearchTitle(text.target.value);
                        setSearchCategory(null)
                        getLessons(text.target.value, null)
                    }}/>
                    <FontAwesomeIcon icon={'search'} color={'#82B21E'} className={'ml-4 mr-4'}/>
                </div>
                <div className={'searchCategories'}>
                    {categories.map((cat, key) => {
                        return <span key={key} onClick={async () => {
                            setSearchCategory(cat.id !== searchCategory ? cat.id : null);
                            setSearchTitle('')
                            getLessons(null, cat.id !== searchCategory ? cat.id : null)
                        }}>{cat.id === searchCategory && <FontAwesomeIcon icon={'check'}/>} {cat.name}</span>
                    })}
                </div>
                {lessons.map((item, key) => {
                    return <Link to={'/service/lesson/' + item.id} onClick={(e) => {
                        if (!item.isBuyed) {
                            e.preventDefault();

                            handleBuy(item);
                        }
                    }}>
                        <div key={key} className={'lessonItem'}>
                            <div>
                                {item.type === 'youtube'
                                    ? <FontAwesomeIcon icon={['fab', 'youtube']} size={'3x'} color={'#264301'}/>
                                    : <FontAwesomeIcon icon={'video'} size={'3x'} color={'#264301'}/>}
                                <span>{item.title}  {item.category ? '(' + item.category.name + ')' : ''}</span>
                            </div>
                            <img src={item.isBuyed ? UnlockIcon : LockIcon} alt={'lock'}/>
                        </div>
                    </Link>
                })}
            </div>
        </div>
    </div>;
}

export default LessonListScreen;
