import React, {useEffect, useState} from "react";
import AdBlock from "../../components/adBlock";
import {withRouter, useParams, useHistory} from 'react-router-dom';
import {Alert} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../tools/ajaxManager";
import {getFlowerbed} from "../../tools/requestManager";
import FlowerbedForm from "../../components/flowerbedForm";
import FlowerbedFormCanvas from "../../components/flowerbedFormCanvas";
import DuplicateModal from "../../components/DuplicateModal";

const Flowerbed = function (props) {
    const auth = useAuth();
    const history = useHistory();
    let {id} = useParams();
    const [countToTariff, setCountToTariff] = useState(0);
    const [data, setData] = useState(null);
    const [isNew, setIsNew] = useState(props.location.pathname === '/flowerbed/create');
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [form, setForm] = useState('square');
    const [name, setName] = useState(null);
    const [nameIsChangind, setNameIsChangind] = useState(null);
    const [error, setError] = useState(null);
    const [goFunc, setGoFunc] = useState(null);

    useEffect(() => {
        setIsNew(props.location.pathname === '/flowerbed/create');
        if (props.location.pathname !== '/flowerbed/create') getData();
    }, [props.location.pathname, auth.token])

    const getData = () => {
        getFlowerbed(id, auth.token, async (response) => {
            setData(response.data);
            setName(response.data.name);
            setForm(response.data.form);
            setWidth(response.data.width);
            setHeight(response.data.height);
        })
    }
    const handleCreate = (to = null) => {
        if (!auth.token) {
            setError('Вы не авторизированы');
            return;
        }
        if (!name || name.length < 1) {
            setError('Вы не заполнили название клумбы');
            return;
        }
        if (!width || width.length < 0) {
            setError('Вы не заполнили длину клумбы');
            return;
        }
        if (!height || height.length < 0) {
            setError('Вы не заполнили ширину клумбы');
            return;
        }
        if (parseInt(height) > 1000) {
            setError('Ширина клумбы не может быть больше 1000 см');
            return;
        }
        if (parseInt(width) > 1000) {
            setError('Длина клумбы не может быть больше 1000 см');
            return;
        }
        if (parseInt(height) < 30) {
            setError('Ширина клумбы не может быть меньше 30 см');
            return;
        }
        if (parseInt(width) < 30) {
            setError('Длина клумбы не может быть меньше 30 см');
            return;
        }

        let formData;
        if (id) {
            formData = {
                name: name,
                form: form,
                width: width,
                height: height,
            };
            if (typeof to === 'string') formData['north'] = to;
        } else {
            formData = new FormData();
            formData.append('name', name);
            formData.append('width', width);
            formData.append('height', height);
            formData.append('form', form);
            if (to) formData.append('north', to);
        }

        request(
            '/flowerbed/' + (id ? id : ''),
            id ? 'PUT' : 'POST',
            formData,
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {
                if ('/flowerbed/' + response.data.id === props.location.pathname) {
                    setData(Object.assign({}, data, response.data));
                    setName(response.data.name);
                    setForm(response.data.form);
                    setWidth(response.data.width);
                    setHeight(response.data.height);
                } else props.history.push('/flowerbed/' + response.data.id);
            },
            (err, code) => {
                if (code === 403) setCountToTariff(countToTariff + 1);
                console.log('ERROR', err)
                if (err.message) alert(err.message);
            }
        )
    }
    const handleDuplicate = () => {
        request(
            '/flowerbed/' + id + '/duplicate',
            'POST',
            {},
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {
                setGoFunc(response.data.id)
            },
            (err, code) => {
                if (code === 403) setCountToTariff(countToTariff + 1);
                console.log('ERROR', err)
            }
        )
    }
    const handleDelete = () => {
        request(
            '/flowerbed/' + id,
            'DELETE',
            {},
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {
                history.push('/flowerbeds');
            },
            (err, code) => {
                if (code === 403) setCountToTariff(countToTariff + 1);
                console.log('ERROR', err)
            }
        )
    }

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage flowerbed'}>
                <h3>Создание клумбы</h3>
                <div className={'flexForm'}>
                    <div>
                        {isNew || nameIsChangind ? <div className={'text-left rowSB '}>
                            <input placeholder={'Название клумбы'} onChange={(e) => {
                                setName(e.target.value);
                            }} defaultValue={name} className={'formField w300 mr-5'}/>
                            {!isNew &&
                            <FontAwesomeIcon onClick={() => setNameIsChangind(false)} icon={'check'}
                                             color={'black'} size={'1x'} className={'pointer'}/>}
                        </div> : <div className={'text-left rowSB'}>
                                    <span onClick={() => setNameIsChangind(true)}>
                                    {name ? name : 'Название клумбы'}
                                        <FontAwesomeIcon icon={'pencil-alt'} color={'black'} size={'1x'}
                                                         className={'ml-2 pointer'}/>
                                </span>
                        </div>}
                    </div>
                    <div>
                        <div className={'text-left rowSB'}>
                            <span className={'mr-5'}>Форма клумбы</span>
                            <select onChange={(e) => {
                                setForm(e.target.value);
                            }} value={form} className={'formField w165'}>
                                <option value={"square"}>Прямоугольник/Квадрат</option>
                                <option value={"ellipse"}>Овал/Круг</option>
                                <option value={"custom"}>Особая форма</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        {!isNew && <div className={'text-left rowSB'}>
                            <span className={'mr-5'}>Цветы для клумбы</span>
                            <button className={'formField w165'}
                                    onClick={() => props.history.push('/flowers/' + id + '/select')}>Выбрать
                            </button>
                        </div>}
                    </div>
                    <div>
                        <div className={'ml-auto mr-auto d-inline-block text-right'}>
                            <p>Размер клумбы (см)</p>
                        </div>
                    </div>
                    <div>
                        <div className={'ml-auto mr-auto d-inline-block text-right'}>
                            <div className={'text-left'}>
                                <input onChange={(e) => {
                                    setWidth(e.target.value);
                                }} defaultValue={width} className={'formField mr-5'} type={'number'} />
                                <input onChange={(e) => {
                                    setHeight(e.target.value);
                                }} defaultValue={height} className={'formField'} type={'number'}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={'ml-auto mr-auto d-inline-block text-right'}>
                            <button className={'createBtn mt-0'} onClick={handleCreate}>Сформировать</button>
                        </div>
                    </div>
                </div>
                {error && error.length > 0 && <Alert variant={'danger'}>
                    {error}
                </Alert>}
                {!isNew && data && data.form === 'custom' && !data.customFormImage ?
                    <FlowerbedFormCanvas data={data} update={(newData) => {
                        setData(newData);
                        setName(newData.name);
                        setForm(newData.form);
                        setWidth(newData.width);
                        setHeight(newData.height);
                    }}/> : (!isNew && data && data.flowers.length > 0 && <FlowerbedForm data={data} update={handleCreate} />)}

                {id && <>
                <p className={'text-center'}>Дополнительные возможности</p>
                <div className={'d-flex flex-row justify-content-center align-items-center'}>
                    <button className={'createBtn mb-5'} onClick={handleDuplicate}>Создать копию</button>
                    <button className={'createBtn mb-5'} onClick={() => {
                        let isDelete = window.confirm('Вы уверены, что хотите удалить данную клумбу?');
                        if (isDelete) handleDelete();
                    }}>Удалить клумбу</button>
                </div></>}
                <DuplicateModal go={goFunc} />
            </div>
        </div>
    </div>;
}

export default withRouter(Flowerbed);
