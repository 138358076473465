import {connect} from "react-redux";
import MainImg from '../../images/main_page_photo.webp'
import {Link, useLocation} from "react-router-dom";
import AdBlock from "../../components/adBlock";
import React from "react";

const Index = function (props) {
    const location = useLocation(); console.log(location)

    const contentRender = () => {
        return <div className={'indexPage'}>
            <h3>Ландшафтный дизайн клумб</h3>
            <p>
                <img src={MainImg} alt="Клумба"/>
                Данный сайт-конструктор помогает сформировать клумбу, которая будет цвести в течение максимально возможного в вашем регионе периода времени.<br/>
                Сайт-конструктор предназначен для садоводов-любителей, студентов факультетов, изучающих растения, ландшафтных дизайнеров.<br/>
                Фильтр сайта-конструктора  отбирает растения в зависимости от их высоты, цвета, периода цветения, местонахождения (в тени или на солнце) и зоны морозостойкости.<br/>
                По растениям, которые вы выбрали для клумбы, будут приходить push-уведомления, напоминающие об уходе за растением, времени его обрезки и т.д.<br/>
                Также сайт-конструктор Flower Garden поможет узнать много интересной информации о разных растениях. На данный момент в базе сайта-конструктора находится примерно 400 видов и подвидов многолетних цветущих травянистых растениях.
            </p>

            <div className={'text-left'} >
                {location.pathname === "/service/about"
                && <Link to={'/service/userAgreement'} className={'text-left pointer blackLink'} >Пользовательское соглашение</Link>}
                <br/>
                {location.pathname === "/service/about"
                && <Link to={'/service/license'} className={'text-left pointer blackLink'} >Лицензионное соглашение</Link>}
            </div>
        </div>
    }

    if (location.pathname === "/service/about") return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            {contentRender()}
        </div>
    </div>;

    return contentRender();
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(Index);
