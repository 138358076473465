import {Breadcrumb, Table, Button, InputGroup, Form} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {createRef, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../tools/ajaxManager";
import AdminLessonTr from "../../components/AdminLessonTr";

const AdminLessons = function (props) {
    // state
    let auth = useAuth();
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState(null);
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [link, setLink] = useState('');

    const filesRef = createRef();

    // effects
    useEffect(() => {
        if (auth.token) {
            request(
                '/lesson/',
                'GET',
                {},
                {
                    "YT-AUTH-TOKEN": "YourTar " + auth.token,
                },
                (response) => {
                    setData(response.data);
                },
                (error) => {
                },
            );
            request(
                '/lesson/category/',
                'GET',
                {},
                {
                    "YT-AUTH-TOKEN": "YourTar " + auth.token,
                },
                (response) => {
                    setCategories(response.data);
                },
                (error) => {
                },
            );
        }
    }, [auth.token]);

    // functions
    function handleAdd(params = null, key = null) {
        if (auth.token) {
            let formData;
            if (params) formData = params;
            else {
                formData = new FormData();
                formData.append('title', title);
                formData.append('price', price);
                if (category) formData.append('category', category);
                formData.append('description', description);
                if (link && link.length > 0)
                    formData.append('link', link);
                else if (filesRef.current.files.length > 0)
                    formData.append('file', filesRef.current.files[0]);
            }

            request(
                '/lesson/',
                'POST',
                formData,
                {
                    "YT-AUTH-TOKEN": "YourTar " + auth.token,
                },
                (response) => {
                    let tmp = [...data];
                    if (!params)
                        tmp.push(response.data);
                    else tmp[key] = response.data;

                    setIsAdd(false);
                    setData(tmp);
                    setLink('');
                    setCategory(null);
                    setTitle('');
                    setDescription('');
                },
                (error) => {
                    alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
                },
            );
        }
    }
    function handleDelete(key) {
        if (auth.token) request(
            '/lesson/' + data[key].id,
            'DELETE',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...data];
                tmp.splice(key, 1);
                setData(tmp);
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }

    function renderAddRow() {
        if (isAdd) {
            return <tr>
                <td></td>
                <td>
                    <Form.Group controlId="name">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Название
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control onChange={(e) => setTitle(e.target.value)}
                                          type="text" />
                        </InputGroup>
                    </Form.Group>
                </td>
                <td>
                    <Form.Group controlId="name">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Описание
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control onChange={(e) => setDescription(e.target.value)}
                                          type="text" as="textarea" rows={3} />
                        </InputGroup>
                    </Form.Group>
                </td>
                <td>
                    <Form.Group controlId="name">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Категория
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" custom
                                          onChange={(e) => setCategory(e.target.value)}>
                                <option value={null}>Не выбрана</option>
                                {categories.map((cat, key) => {
                                    return <option key={key} value={cat.id}>{cat.name}</option>;
                                })}
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>
                </td>
                <td>
                    <Form.Group controlId="name">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Сслыка
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control onChange={(e) => setLink(e.target.value)}
                                          type="text" />
                        </InputGroup>
                    </Form.Group>
                    <p>или</p>
                    <Form.Control placeholder="Выберите файл" type={'file'} ref={filesRef} />
                </td>
                <td>
                    <Form.Group controlId="price">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Цена
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control onChange={(e) => setPrice(e.target.value)}
                                          type="number" />
                        </InputGroup>
                    </Form.Group>
                </td>
                <td>
                    <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                        setIsAdd(false);
                        setLink('');
                        setDescription('');
                        setTitle('');
                    }}>
                        <FontAwesomeIcon icon={'undo'} className={'mr-2'}/>
                        Отмена
                    </Button>
                    <Button className={'text-right'} variant="success" size={'sm'}
                            onClick={() => handleAdd()}>
                        <FontAwesomeIcon icon={'check'} className={'mr-2'}/>
                        Сохранить
                    </Button>
                </td>
            </tr>;
        }
        else {
            return <tr>
                <td colSpan={7}>
                    <Button className={'text-right'} variant="success" size={'sm'}
                            onClick={() => setIsAdd(true)}>
                        <FontAwesomeIcon icon={'plus'} className={'mr-2'}/>
                        Добавить
                    </Button>
                </td>
            </tr>
        }
    }

    return <div className={'content'}>
        <h3>Список уроков</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список уроков</Breadcrumb.Item>
        </Breadcrumb>

        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Заголовок</th>
                    <th>Описание</th>
                    <th>Категория</th>
                    <th>Тип</th>
                    <th>Цена</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {renderAddRow()}
                {data.map((item, key) => {
                    return <AdminLessonTr num={key} key={item.id} data={item} delete={handleDelete} edit={handleAdd}
                                          categories={categories} />;
                })}
                </tbody>
            </Table>
        </div>
    </div>;
}

export default AdminLessons;
