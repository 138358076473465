import {Route, Switch} from "react-router-dom";
import NoMatch from "../pages/NoMatch";
import Index from "../pages/public";
import Header from "../components/header";
import Footer from "../components/footer";
import AdBlock from "../components/adBlock";
import Auth from "../pages/public/auth";
import PasswordResetScreen from "../pages/public/passwordReset";
import Policy from "../pages/public/policy";
import Instruction from "../pages/public/instruction";

const PublicLayout = function (props) {
    return <>
        <Header/>
        <div className={'contentLayout'}>
            <div className={'leftSidePart'}>
                <AdBlock />
            </div>
            <div className={'rightSidePart'}>
                <Switch>
                    <Route exact path="/register">
                        <Auth />
                    </Route>
                    <Route exact path="/login">
                        <Auth />
                    </Route>
                    <Route exact path="/password/reset">
                        <PasswordResetScreen />
                    </Route>
                    <Route exact path="/policy">
                        <Policy />
                    </Route>
                    <Route exact path="/license">
                        <Policy />
                    </Route>
                    <Route exact path="/fbdelete">
                        <Instruction />
                    </Route>
                    <Route exact path="/">
                        <Index/>
                    </Route>
                    <Route path="*">
                        <NoMatch/>
                    </Route>
                </Switch>
            </div>
        </div>
        <Footer />
    </>;
}

export default PublicLayout;
