export const months = [
    {
        number: 1,
        name: 'Январь',
        short: 'Янв',
        rome: 'I',
        forDate: 'января',
    },
    {
        number: 2,
        name: 'Февраль',
        short: 'Фев',
        rome: 'II',
        forDate: 'февраля',
    },
    {
        number: 3,
        name: 'Март',
        short: 'Мар',
        rome: 'III',
        forDate: 'марта',
    },
    {
        number: 4,
        name: 'Апрель',
        short: 'Апр',
        rome: 'IV',
        forDate: 'апреля',
    },
    {
        number: 5,
        name: 'Май',
        short: 'Май',
        rome: 'V',
        forDate: 'мая',
    },
    {
        number: 6,
        name: 'Июнь',
        short: 'Июн',
        rome: 'VI',
        forDate: 'июня',
    },
    {
        number: 7,
        name: 'Июль',
        short: 'Июл',
        rome: 'VII',
        forDate: 'июля',
    },
    {
        number: 8,
        name: 'Август',
        short: 'Авг',
        rome: 'VIII',
        forDate: 'августа',
    },
    {
        number: 9,
        name: 'Сентябрь',
        short: 'Сен',
        rome: 'IX',
        forDate: 'сентября',
    },
    {
        number: 10,
        name: 'Октябрь',
        short: 'Окт',
        rome: 'X',
        forDate: 'октября',
    },
    {
        number: 11,
        name: 'Ноябрь',
        short: 'Ноя',
        rome: 'XI',
        forDate: 'ноября',
    },
    {
        number: 12,
        name: 'Декабрь',
        short: 'Дек',
        rome: 'XII',
        forDate: 'декабря',
    },
];