import {Breadcrumb, Table, Button, Form, Col} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {useEffect, useState, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../tools/ajaxManager";
import CustomPagination from "../../components/customPagination";

const AdminJobList = function (props) {
    //refs
    const ref = useRef();
    // state
    let auth = useAuth();
    const [jobs, setJobs] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);

    // effects
    useEffect(() => {
        if (auth.token) request(
            '/job/?offset=' + ((page - 1) * 30),
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                setJobs(response.data);
                setCount(response.count);
            },
            (error) => {
            },
        );
    }, [auth.token, page]);

    // functions
    function handleCreate(e) {
        e.preventDefault();
        if (!file) {
            setError('Файл не выбран! Выберите пожалуйста файл.');
            return;
        } else setError(null);

        let data = new FormData();
        data.append('file', file);
        if (auth.token) request(
            '/job/',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...jobs];
                tmp.unshift(response.data);
                setJobs(tmp);
                ref.current.value = null;
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }
    function handleDelete(key) {
        if (auth.token) request(
            '/job/' + jobs[key].id,
            'DELETE',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...jobs];
                tmp.splice(key, 1);
                setJobs(tmp);
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }
    function showStatus(status) {
        let result = null;

        switch(status) {
            case 'pending':
                result = <div>
                    <FontAwesomeIcon icon={'hourglass'}/>
                    <span className={'ml-2 text-secondary'}>Ожидание...</span>
                </div>;
                break;
            case 'process':
                result = <div>
                    <FontAwesomeIcon icon={'download'} className={'text-primary'}/>
                    <span className={'ml-2 text-primary'}>В процессе выполнения.</span>
                </div>;
                break;
            case 'error':
                result = <div>
                    <FontAwesomeIcon icon={'bug'} className={'text-danger'}/>
                    <span className={'ml-2 text-danger'}>Ошибка.</span>
                </div>;
                break;
            case 'done':
                result = <div>
                    <FontAwesomeIcon icon={'thumbs-up'} className={'text-success'}/>
                    <span className={'ml-2 text-success'}>Успешно.</span>
                </div>;
                break;
            default:
                result = null;
        }

        return result;
    }

    return <div className={'content'}>
        <h3>Список работ по импорту</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список работ по импорту</Breadcrumb.Item>
        </Breadcrumb>

        <Form onSubmit={handleCreate}>
            <Form.Row className="align-items-center">
                <Col xs="auto">
                    <p className={'my-auto'}>Загрузить новый файл:</p>
                </Col>
                <Col xs="auto">
                    <Form.Group>
                        <Form.File id="exampleFormControlFile1" label="" accept={'.csv'} onChange={(e) => {
                            setFile(e.target.files[0]);
                        }} ref={ref}/>
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Button type="submit" className="mb-2" variant={'success'} onClick={handleCreate}>
                        <FontAwesomeIcon icon={'plus'}/> Загрузить
                    </Button>
                </Col>
            </Form.Row>
        </Form>
        {error && <p className={'alert alert-danger'} role={'alert'}>{error}</p>}

        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Дата</th>
                    <th>Статус</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {jobs.map((item, key) => {
                    let date = new Date(item.updated);
                    return <tr key={key}>
                        <td>{((page - 1) * 30) + (key + 1)}</td>
                        <td>{date.getHours()}:{date.getMinutes()} {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</td>
                        <td>{showStatus(item.status)}</td>
                        <td>
                            <Button className={'text-right'} variant="danger" size={'sm'}
                                    onClick={() => handleDelete(key)}>
                                <FontAwesomeIcon icon={'trash'}/>
                            </Button>
                        </td>
                    </tr>;
                })}
                </tbody>
            </Table>
        </div>

        <CustomPagination update={setPage} count={Math.ceil(count / 30)}/>
    </div>;
}

export default AdminJobList;