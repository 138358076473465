import {Breadcrumb, Table, Button} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../tools/ajaxManager";
import CustomPagination from "../../components/customPagination";

const AdminDelayedNotificationList = function (props) {
    // state
    let auth = useAuth();
    const [feedbacks, setFeedbacks] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    // effects
    useEffect(() => {
        if (auth.token) request(
            '/notification/?offset=' + ((page - 1) * 30),
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => { console.log(response)
                setFeedbacks(response.data);
                setCount(response.count);
            },
            (error) => {
            },
        );
    }, [auth.token, page]);

    // functions
    function handleDelete(key) {
        if (auth.token) request(
            '/notification/' + feedbacks[key].id,
            'DELETE',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...feedbacks];
                tmp.splice(key, 1);
                setFeedbacks(tmp);
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }

    return <div className={'content'}>
        <h3>Список отложенных уведомлений</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список отложенных уведомлений</Breadcrumb.Item>
        </Breadcrumb>

        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Контент</th>
                    <th>Дата</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {feedbacks.map((item, key) => {
                    let date = new Date(item.moment);
                    return <tr key={key}>
                        <td>{((page - 1) * 30) + (key + 1)}</td>
                        <td>{item.content}</td>

                        <td>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</td>
                        <td>
                            <Button className={'text-right'} variant="danger" size={'sm'}
                                    onClick={() => handleDelete(key)}>
                                <FontAwesomeIcon icon={'trash'}/>
                            </Button>
                        </td>
                    </tr>;
                })}
                </tbody>
            </Table>
        </div>

        <CustomPagination update={setPage} count={Math.ceil(count / 30)}/>
    </div>;
}

export default AdminDelayedNotificationList;
