import {Breadcrumb, Col, Button, Form, InputGroup} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {useEffect, useState} from "react";
import { useHistory, withRouter } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../tools/ajaxManager";
import {months} from "../../tools/data";

const AdminFlowerForm = function (props) {
    // state
    let auth = useAuth();
    const history = useHistory();
    const [object, setObject] = useState(null);
    const [name, setName] = useState('');
    const [latName, setLatName] = useState('');
    const [ground, setGround] = useState('');
    const [note, setNote] = useState('');
    const [humidity, setHumidity] = useState('');
    const [diameter, setDiameter] = useState(null);
    const [minHeight, setMinHeight] = useState(null);
    const [maxHeight, setMaxHeight] = useState(null);
    const [frostResistMin, setFrostResistMin] = useState(0);
    const [frostResistMax, setFrostResistMax] = useState(0);
    const [minMonth, setMinMonth] = useState(1);
    const [maxMonth, setMaxMonth] = useState(1);
    const [isSun, setIsSun] = useState(false);
    const [isShadow, setIsShadow] = useState(false);
    const [isPenumbra, setIsPenumbra] = useState(false);
    const [bloomColors, setBloomColors] = useState([]);
    const [monthColors, setMonthColors] = useState(['', '', '', '', '', '', '', '', '', '', '', '',]);
    const [error, setError] = useState(null);

    // effects
    useEffect(() => {
        if (props.location.state && props.location.state.object) {
            setObject(props.location.state.object);
            setName(props.location.state.object.name ?? '');
            setHumidity(props.location.state.object.humidity ?? '');
            setLatName(props.location.state.object.latName ?? '');
            setGround(props.location.state.object.ground ?? '');
            setNote(props.location.state.object.note ?? '');
            setDiameter(props.location.state.object.diameter ?? null);
            setMinHeight(props.location.state.object.minHeight ?? null);
            setMaxHeight(props.location.state.object.maxHeight ?? null);
            setFrostResistMin(props.location.state.object.frostResistanceMin ?? 0);
            setFrostResistMax(props.location.state.object.frostResistanceMax ?? 0);
            setMinMonth(props.location.state.object.minMonth ?? 1);
            setMaxMonth(props.location.state.object.maxMonth ?? 1);
            setBloomColors(props.location.state.object.bloomColors ?? []);
            setMonthColors(props.location.state.object.monthColors ?? ['', '', '', '', '', '', '', '', '', '', '', '',]);
            setIsSun(props.location.state.object ? (props.location.state.object.isSun ? 1 : 0) : false);
            setIsPenumbra(props.location.state.object ? (props.location.state.object.isPenumbra ? 1 : 0) : false);
            setIsShadow(props.location.state.object ? (props.location.state.object.isShadow ? 1 : 0) : false);
        }
    }, [props.location.state])

    // functions
    function handleSubmit(e) {
        e.preventDefault();
        let data, method;
        if (object) {
            method = 'PUT';
            data = {
                "name": name,
                "lat_name": latName,
                "min_height": minHeight,
                "max_height": maxHeight,
                "min_month": minMonth,
                "max_month": maxMonth,
                "is_sun": isSun ? 1 : 0,
                "is_shadow": isShadow ? 1 : 0,
                "is_penumbra": isPenumbra ? 1 : 0,
                "frost_resistance_min": frostResistMin,
                "frost_resistance_max": frostResistMax,
                "diameter": diameter,
                "ground": ground,
                "note": note,
                "humidity": humidity,
                "bloom_colors": bloomColors,
                "month_colors": monthColors,
            };
        }
        else {
            method = 'POST';
            data = new FormData();
            data.append('name', name);
            data.append('lat_name', latName);
            data.append('humidity', humidity);
            data.append('min_height', minHeight);
            data.append('max_height', maxHeight);
            data.append('min_month', minMonth);
            data.append('max_month', maxMonth);
            data.append('is_sun', isSun ? 1 : 0);
            data.append('is_shadow', isShadow ? 1 : 0);
            data.append('is_penumbra', isPenumbra ? 1 : 0);
            data.append('frost_resistance_min', frostResistMin);
            data.append('frost_resistance_max', frostResistMax);
            data.append('diameter', diameter);
            data.append('ground', ground);
            data.append('note', note);
            for (let i = 0; i < bloomColors.length; i++) {
                data.append('bloom_colors[]', bloomColors[i]);
            }
            for (let i = 0; i < monthColors.length; i++) {
                data.append('month_colors[]', monthColors[i]);
            }
        }

        if (auth.token) request(
            '/flower/' + (object ? object.id : ''),
            method,
            data,
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                history.push('/admin/flowers?page=' + (props.location.state.page ?? 1));
            },
            (error) => {
                setError(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }

    return <div className={'content'}>
        <h3>{object ? 'Редактировать' : 'Добавить'} растение</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/admin/flowers">
                Список растений
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{object ? 'Редактировать' : 'Добавить'} растение</Breadcrumb.Item>
        </Breadcrumb>

        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Название
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control onChange={(e) => setName(e.target.value)}
                                  type="text" value={name}/>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="lat_name">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Название (лат.)
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control onChange={(e) => setLatName(e.target.value)}
                                  type="text" value={latName}/>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="diameter">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Диаметр (см)
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control onChange={(e) => setDiameter(e.target.value)}
                                  type="number" value={diameter}/>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="min_height">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Мин. высота (см)
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control onChange={(e) => setMinHeight(e.target.value)}
                                  type="number" value={minHeight}/>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="max_height">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Макс. высота (см)
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control onChange={(e) => setMaxHeight(e.target.value)}
                                  type="number" value={maxHeight}/>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="min_month">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Цветет с
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control value={minMonth} as="select"
                                  onChange={(e) => setMinMonth(e.target.value)}>
                        {months.map((item, key) => {
                            return <option value={item.number} key={key}>{item.name}</option>;
                        })}
                    </Form.Control>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="max_month">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Цветет по
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control value={maxMonth} as="select"
                                  onChange={(e) => setMaxMonth(e.target.value)}>
                        {months.map((item, key) => {
                            return <option value={item.number} key={key}>{item.name}</option>;
                        })}
                    </Form.Control>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="sun">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Освещение
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <div key={`inline-checkbox`} className=" ml-3">
                        <Form.Check inline label="Солнце" type={'checkbox'} id={`inline-checkbox-1`} onChange={(e) => {
                            setIsSun(e.target.checked);
                        }} checked={isSun}/>
                        <Form.Check inline label="Полутень" type={'checkbox'} id={`inline-checkbox-2`}
                                    onChange={(e) => {
                                        setIsPenumbra(e.target.checked);
                                    }} checked={isPenumbra}/>
                        <Form.Check inline label="Тень" type={'checkbox'} id={`inline-checkbox-2`} onChange={(e) => {
                            setIsShadow(e.target.checked);
                        }} value={isShadow}/>
                    </div>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="frost">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Морозостойкость (min)
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control onChange={(e) => setFrostResistMin(e.target.value)}
                                  type="number" value={frostResistMin}/>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="frostM">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Морозостойкость (max)
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control onChange={(e) => setFrostResistMax(e.target.value)}
                                  type="number" value={frostResistMax}/>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="name">
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Влажность
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control onChange={(e) => setHumidity(e.target.value)}
                                  type="text" value={humidity}/>
                </InputGroup>
            </Form.Group>

            <Form.Group controlId="bloom">
                <Form.Label>Цвет цветения</Form.Label>
                <Form.Row>
                    {(Array.isArray(bloomColors) ? bloomColors : Object.values(bloomColors)).map((item, key) => {
                        return <Col md={'3'} key={key}>
                            <InputGroup className="mb-2">
                                <Form.Control type={'color'} value={item} onChange={(e) => {
                                    let tmp = [...bloomColors];
                                    tmp[key] = e.target.value;
                                    setBloomColors(tmp);
                                }}/>
                                <InputGroup.Append onClick={() => {
                                    let tmp = [...bloomColors];
                                    tmp.splice(key, 1);
                                    setBloomColors(tmp);
                                }}>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={'trash'}/>
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>;
                    })}
                    <Col md={'3'}>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            let tmp = [...bloomColors];
                            tmp.push('#000000');
                            setBloomColors(tmp);
                        }} variant="outline-success" type="submit">
                            <FontAwesomeIcon icon={'plus'}/> Добавить цвет
                        </Button>
                    </Col>
                </Form.Row>
            </Form.Group>
            <Form.Group controlId="months">
                <Form.Label>Цвет цветения</Form.Label>
                <Form.Row>
                    {monthColors.map((item, key) => {
                        if ((key + 1) < minMonth || (key + 1) > maxMonth)
                            return <Col md={'3'} key={key}>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            {months[key].short}
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type={'color'} value={item} onChange={(e) => {
                                        let tmp = [...monthColors];
                                        tmp[key] = e.target.value;
                                        setMonthColors(tmp);
                                    }}/>
                                </InputGroup>
                            </Col>;
                        else return <Col md={'3'} key={key}>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        {months[key].short}
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type={'text'} disabled={true} value={'цветение'} />
                            </InputGroup>
                        </Col>;
                    })}
                </Form.Row>
            </Form.Group>

            <Form.Group controlId="ground">
                <Form.Label>Заметка</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder={"Введите описание почвы"} value={ground}
                              onChange={(e) => setGround(e.target.value)}/>
            </Form.Group>

            {error && <p className={'alert alert-danger'} role={'alert'}>{error}</p>}
            <Button onClick={handleSubmit} variant="success" type="submit">
                <FontAwesomeIcon icon={'save'}/> Сохранить
            </Button>
        </Form>
    </div>;
}

export default withRouter(AdminFlowerForm);
