import React from "react";
import AdBlock from "../../components/adBlock";
import {Link, useHistory} from 'react-router-dom';

import ProjImg from '../../images/icon_project.webp';
import VideoImg from '../../images/icon_video.webp';
import QAImg from '../../images/icon_qa.webp';
import NoteImg from '../../images/icon_note.webp';
import AboutImg from '../../images/icon_about.webp';
import TariffImg from '../../images/icon_tariff.webp';

const data = [
    {
        title: 'Тарифы',
        description: 'На странице представлены актуальные тарифы на продукты сервиса с самыми выгодными расценками.',
        image: TariffImg,
        link: '/service/tariff',
    },
    {
        title: 'Заказать проект',
        description: 'У нас вы можете заказать проект клумбы любой сложности по оптимальной цене. Оставьте заявку для получения бесплатной консультации.',
        image: ProjImg,
        link: '/service/qa',
    },
    {
        title: 'Видеоуроки',
        description: 'Видео-уроки ландшафтного дизайна для начинающих, чтобы самостоятельно научиться создавать проекты.',
        image: VideoImg,
        link: '/service/lesson',
    },
    {
        title: 'Заметки',
        description: 'С помощью сервиса заметки удобно записывать идеи, сохранять всё важное, быстро находить нужное.',
        image: NoteImg,
        link: '/service/note',
    },
    {
        title: 'Обратная связь',
        description: 'По всем вопросам, замечаниям и деловым предложениям Вы можете  связаться через форму обратной связи.',
        image: QAImg,
        link: '/service/tech',
    },
    {
        title: 'О нас',
        description: 'На странице размещена информация о сервисе, пользовательское соглашение и договор оферты.',
        image: AboutImg,
        link: '/service/about',
    },
];

const Services = function (props) {
    let history = useHistory();

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <h3>Сервисы</h3>
                <div className={'services'}>
                    {data.map((item, key) => {
                        return <Link to={item.link} className={'text-decoration-none'} key={key} onClick={(e) => {
                            if (item.click) {
                                e.preventDefault();
                                item.click();
                            }
                        }}>
                            <div className={'serviceCell'}>
                                <div className={'serviceContainer'}>
                                    <p className={'serviceTitle'}>
                                        {item.title}
                                    </p>
                                    <p className={'serviceDescription'}>
                                        <img alt={'Icon'} src={item.image}/>
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        </Link>;
                    })}
                </div>
            </div>
        </div>
    </div>;
}

export default Services;