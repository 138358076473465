import React, {useState, useEffect} from "react";
import request from "../../tools/ajaxManager";
import {connect} from "react-redux";
import {useParams, withRouter} from 'react-router-dom';
import {Table, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import CustomPagination from "../../components/customPagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FlowerFilter from '../../components/flowerFilter';
import FlowerInfoModal from '../../components/flowerInfoModal';
import MonthColors from "../../components/monthColors";
import {getFlowerbed} from "../../tools/requestManager";
import ToTariffModal from "../../components/ToTariffModal";
import {useAuth} from "../../context/provideAuth";

const FlowerList = function (props) {
    let {id} = useParams();
    const auth = useAuth();
    const [flowers, setFlowers] = useState(null);
    const [colors, setColors] = useState([]);
    const [colorNames, setColorNames] = useState([]);
    const [frosts, setFrosts] = useState([]);
    const [humidities, setHumidities] = useState([]);
    const [count, setCount] = useState(null);
    const [countToTariff, setCountToTariff] = useState(0);
    const [filter, setFilter] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [page, setPage] = useState(1);
    const [modalData, setModalData] = useState(null);
    const [flowerbed, setFlowerbed] = useState(null);
    const [modalCount, setModalCount] = useState(0);
    const [pageForAddToFlowerbed, setPageForAddToFlowerbed] = useState(props.location.pathname !== '/flowers');

    useEffect(() => {
        getFlowers();
        request(
            '/color/',
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                setColorNames(response.data);
            },
            (error) => {
            },
        );
    }, []);
    useEffect(() => {
        getFlowers();
    }, [isFavorite, filter]);
    useEffect(() => {
        getFlowers(true);
    }, [page]);
    useEffect(() => {
        setPageForAddToFlowerbed(props.location.pathname !== '/flowers')
        if (id) getFlowerbed(id, props.user.token, async (response) => {
            setFlowerbed(response.data);
        })
    }, [props.location.pathname])

    const getFlowers = (loadMore = false) => {
        let paramString = '';
        if (!isFavorite) {
            let params = loadMore ? Object.assign({}, filter) : Object.assign({}, filter, {
                frostNeed: 1,
                countNeed: 1,
                colorsNeed: 1,
                humiditiesNeed: 1,
            });
            if (flowers && flowers.length > 0 && loadMore) params = Object.assign({}, params, {
                offset: 30 * (page - 1),
            });
            paramString = Object.keys(params).map((key) => {
                let str;
                if (Array.isArray(params[key])) {
                    str = '';
                    params[key].forEach((item, index) => {
                        str += (index > 0 ? '&' : '') + encodeURIComponent(key) + '[]=' + encodeURIComponent(item)
                    });
                } else str = encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
                return str;
            }).join('&');
        }

        request(
            isFavorite ? '/flower/favorite' : '/flower/?' + paramString,
            'GET',
            {},
            {
                'YT-AUTH-TOKEN': 'YourTar ' + props.user.token,
            },
            async (response) => {
                setFlowers(response.data);
                if (!loadMore) setPage(1)
                if (response.count) setCount(response.count);
                if (response.frosts) setFrosts(response.frosts);
                if (response.colors) setColors(response.colors);
                if (response.humidities) setHumidities(response.humidities);
            },
            (err, code) => {
                if (code === 403) setCountToTariff(countToTariff + 1);
                console.log('ERROR', err)
            }
        )
    };
    const handleFavorite = (key) => {
        request(
            '/flower/favorite/' + flowers[key].id,
            flowers[key].isFavorite ? 'DELETE' : 'POST',
            {},
            {
                'YT-AUTH-TOKEN': 'YourTar ' + props.user.token,
            },
            async (response) => {
                let tmp = [...flowers];
                if (isFavorite)
                    tmp.splice(key, 1);
                else tmp[key].isFavorite = !tmp[key].isFavorite;
                setFlowers(tmp);
            },
            (err) => {
                console.log('ERROR', err)
            }
        )
    };
    const addToFlowerbed = (key) => {
        let formData = new FormData();
        formData.append('id', flowerbed.id);
        formData.append('flower_id', flowers[key].id)

        request(
            '/flowerbed/flower/toggle',
            'POST',
            formData,
            {
                'YT-AUTH-TOKEN': 'YourTar ' + props.user.token,
            },
            async (response) => {
                let tmp = response.data;
                if (!Array.isArray(tmp.flowers)) tmp.flowers = Object.values(tmp.flowers);
                setFlowerbed(tmp);
            },
            (err, code) => {
                if (code === 403) setCountToTariff(countToTariff + 1);
                console.log('ERROR', err)
            }
        )
    };

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <FlowerFilter filter={filter} onUpdate={(data) => {
               setFilter(data);
            }} colors={colors} colorNames={colorNames} frosts={frosts} humidities={humidities} isFavoriteData={isFavorite} isFavorite={() => {
                setIsFavorite(!isFavorite);
                setPage(1);
            }}/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <div className={pageForAddToFlowerbed ? 'rowSB' : ''}>
                    <h3>
                        {pageForAddToFlowerbed ? 'Добавление растений на клумбу' : isFavorite ? 'Избранное' : 'Растения и их характеристики'}
                        {!pageForAddToFlowerbed && <OverlayTrigger
                            trigger="hover"
                            placement={'bottom'}
                            overlay={
                                <Popover id={`popover-positioned-bottom`}>
                                    <Popover.Title as="h3">{`Информация!`}</Popover.Title>
                                    <Popover.Content>
                                        Информация по растениям взята из разных источников, энциклопедий и сети
                                        интернет. В случае если информация разнится с вашей, просьба известить нас. В
                                        разделе "Сервисы" есть форма обратной связи!
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <FontAwesomeIcon icon={'info-circle'} className={'ml-2 pointer'}/>
                        </OverlayTrigger>}
                    </h3>
                    {pageForAddToFlowerbed && <button onClick={() => {
                        props.history.push('/flowerbed/' + id);
                    }} className={'darkBtn'}>
                        Готово
                    </button>}
                </div>
                <Table responsive>
                    <thead>
                    <tr>
                        <th style={{maxWidth: 300,}}>Название</th>
                        <th style={{maxWidth: 150,}}>Высота в период{'\n'}цветения</th>
                        <th style={{minWidth: 170,}}>Освещение</th>
                        <th style={{maxWidth: 100,}}>Солнце/{'\n'}тень</th>
                        <th style={{maxWidth: 100,}}>Зона{'\n'}морозо-{'\n'}стойкости</th>
                        <th>{pageForAddToFlowerbed ? 'В клумбе' : 'Избранное'}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {flowers && flowers.map((item, key) => {
                        let sunArr = [], bloom;
                        if (item.isSun) sunArr.push('солнце');
                        if (item.isPenumbra) sunArr.push('полутень');
                        if (item.isShadow) sunArr.push('тень');
                        if (typeof item.bloomColors === 'object' && item.bloomColors !== null)
                            bloom = Object.values(item.bloomColors);
                        else bloom = item.bloomColors;

                        return <tr key={item.id} className={'customTr'}>
                            <td style={{maxWidth: 300,}} onClick={() => {
                                setModalData(item);
                                setModalCount(modalCount + 1);
                            }} className={'pointer'}>
                                {item.name}<br/>
                                ({item.latName})
                            </td>
                            <td style={{maxWidth: 150,}}>
                                {item.minHeight} - {item.maxHeight}
                            </td>
                            <td style={{minWidth: 170,}}>
                                {bloom.map((bc, index) => {
                                    let obj = colorNames.find(name => name.code === bc);
                                    let name = obj ? obj.name : 'Имя не найдено';
                                    return <OverlayTrigger key={index}
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{name}</Tooltip>}
                                    >
                                        <div onClick={() => {
                                        let tmp = [...flowers];
                                        tmp[key].activeColor = bc;
                                        setFlowers(tmp);
                                    }} className={'colorCircle'}
                                                style={{backgroundColor: bc ?? '#000000', marginBottom: 10,}}/>
                                    </OverlayTrigger>
                                })}
                                <MonthColors activeColor={item.activeColor ?? bloom[0]} data={item} width={12}/>
                            </td>
                            <td style={{maxWidth: 100,}}>
                                {sunArr.join(', ')}
                            </td>
                            <td style={{maxWidth: 100,}}>{item.frostResistanceMin !== item.frostResistanceMax
                                ? item.frostResistanceMin + '-' + item.frostResistanceMax : item.frostResistanceMin}</td>
                            <td>
                                {pageForAddToFlowerbed ?
                                    <div onClick={() => addToFlowerbed(key)}
                                         className={'customCheckbox ' + (flowerbed && flowerbed.flowers.filter(fl => fl.id === item.id).length > 0 ?
                                             'active' : '')}>
                                        <FontAwesomeIcon icon={'check'}/>
                                    </div> : isFavorite ? <div onClick={() => handleFavorite(key)}>
                                        <FontAwesomeIcon icon={'trash'}/>
                                    </div> : <div onClick={() => handleFavorite(key)}
                                                  className={'customCheckbox ' + (item.isFavorite ? 'active' : '')}>
                                        <FontAwesomeIcon icon={'check'}/>
                                    </div>}
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>
                <CustomPagination update={setPage} currentPage={page} count={Math.ceil(count / 30)}/>
            </div>
        </div>
        <FlowerInfoModal data={modalData} count={modalCount} colorNames={colorNames}/>
        <ToTariffModal count={countToTariff} />
    </div>;
};

export default withRouter(connect((state /*, ownProps*/) => {
    return state;
}, dispatch => ({}))(FlowerList));
