import React, {useEffect, useState} from "react";
import AdBlock from "../../components/adBlock";
import {Link} from 'react-router-dom';
import {useAuth} from "../../context/provideAuth";
import request from "../../tools/ajaxManager";
import NoteListItem from "../../components/NoteListItem";

const NoteListScreen = function (props) {
    const [notes, setNotes] = useState([]);
    const auth = useAuth();

    useEffect(() => {
        if (auth.token) {
            request(
                '/note/',
                'GET',
                {},
                {
                    'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
                },
                async (response) => {
                    setNotes(response.data);
                },
                (err) => {
                    console.log('ERROR', err)
                }
            )
        }
    }, [auth.token]);

    const removeElement = (key) => {
        let tmp = [...notes];
        tmp.splice(key, 1);
        setNotes(tmp);
    };

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <div className={'card1'}>
                    <div>
                        <div className={'card1Tab'}>
                            <span>Заметки</span>
                        </div>
                    </div>
                    <div className={'divider'}/>
                    <div className={'mt-4 ml-5 mr-5 pb-5'}>
                        <Link to={{
                            pathname: '/service/note/create',
                            state: {}
                        }}>
                            <div className={'noteItem noteButton pointer'}>
                                Создать
                            </div>
                        </Link>
                        {notes.map((item, key) => {
                            return <NoteListItem key={item.id} data={item} remove={removeElement}/>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default NoteListScreen;