import {Route, Switch} from "react-router-dom";
import NoMatch from "../pages/NoMatch";
import FlowerList from "../pages/private/flowers";
import Profile from "../pages/private/profile";
import Header from "../components/header";
import Footer from "../components/footer";
import Services from "../pages/private/services";
import FeedbackScreen from "../pages/private/Feedback";
import PasswordChangeScreen from "../pages/private/passwordChange";
import NoteListScreen from "../pages/private/NoteList";
import NoteScreen from "../pages/private/Note";
import FlowerbedList from "../pages/private/flowerbedList";
import Flowerbed from "../pages/private/flowerbed";
import Index from "../pages/public";
import Policy from "../pages/public/policy";
import Tariffs from "../pages/private/tariffs";
import LessonListScreen from "../pages/private/LessonList";
import LessonScreen from "../pages/private/Lesson";

const PrivateLayout = function (props) {
    return <div>
        <Header/>
        <Switch>
            <Route exact path="/flowers">
                <FlowerList/>
            </Route>
            <Route exact path="/flowerbeds">
                <FlowerbedList />
            </Route>
            <Route exact path="/flowerbed/create">
                <Flowerbed />
            </Route>
            <Route exact path="/flowers/:id/select">
                <FlowerList/>
            </Route>
            <Route exact path="/flowerbed/:id">
                <Flowerbed />
            </Route>
            <Route exact path="/profile">
                <Profile />
            </Route>
            <Route exact path="/profile/password/change">
                <PasswordChangeScreen />
            </Route>
            <Route exact path="/service">
                <Services />
            </Route>
            <Route exact path="/service/qa">
                <FeedbackScreen />
            </Route>
            <Route exact path="/service/tech">
                <FeedbackScreen />
            </Route>
            <Route exact path="/service/note">
                <NoteListScreen />
            </Route>
            <Route exact path="/service/note/create">
                <NoteScreen />
            </Route>
            <Route exact path="/service/note/edit">
                <NoteScreen />
            </Route>
            <Route exact path="/service/about">
                <Index />
            </Route>
            <Route exact path="/service/userAgreement">
                <Policy />
            </Route>
            <Route exact path="/service/license">
                <Policy />
            </Route>
            <Route exact path="/service/lesson">
                <LessonListScreen />
            </Route>
            <Route exact path="/service/lesson/:id">
                <LessonScreen />
            </Route>
            <Route exact path="/service/tariff">
                <Tariffs />
            </Route>
            <Route path="*">
                <NoMatch/>
            </Route>
        </Switch>
        <Footer/>
    </div>;
}

export default PrivateLayout;
