import React, {useState, useRef} from "react";
import AdBlock from "../../components/adBlock";
import {useAuth} from "../../context/provideAuth";
import {serverImageUrl} from "../../tools/parameters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import request from "../../tools/ajaxManager";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const Profile = function (props) {
    const auth = useAuth();
    const [city, setCity] = useState(auth.user.city ?? '');
    const [name, setName] = useState(auth.user.name ?? '');
    const [role, setRole] = useState(auth.user.category ?? 'student');
    const [isMale, setIsMale] = useState(auth.user.isMale);
    const [birth, setBirth] = useState(auth.user.bornYear ?? '');
    const [avatar, setAvatar] = useState(null);
    const [isSocial] = useState(auth.user.email.indexOf('vk.com') !== -1 ||
        auth.user.email.indexOf('fb.com') !== -1 ||
        auth.user.email.indexOf('@appleId.com') !== -1);
    const [email, setEmail] = useState(isSocial ? '' : auth.user.email);
    const [isEdit, setIsEdit] = useState(false);

    let filesRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isEdit) {
            setIsEdit(true);
            return;
        }

        let data = new FormData();
        data.append('email', email);
        data.append('city', city);
        data.append('born_year', birth);
        data.append('name', name);
        data.append('category', role);
        if (filesRef.current.files[0]) data.append('avatar', filesRef.current.files[0]);
        data.append('is_male', isMale ? 1 : 0);

        request(
            '/user/',
            'POST',
            data,
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {
                props.onLogin({
                    user: response.user,
                    token: auth.token,
                });
                setIsEdit(false);
            },
            (err) => {
                console.log('ERROR', err)
            }
        )
    };
    const handleConfirm = () => {
        request(
            '/user/confirm',
            'GET',
            {},
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {

                alert('Письмо отправлено! Проверьте пожалуйста почту. В т.ч. папку "Спам"')
            },
            (err) => {
                if (err.message) {
                    alert(err.message);
                }
            }
        )
    }
    const oldOptionRender = () => {
        let arr = [];
        for (let i = (new Date()).getFullYear(); i >= 1900; i--)
            arr.push(<option key={i} value={i}>{i}</option>);
        return arr;
    }

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <h3>Мой профиль</h3>
                <form className={'profileForm'} onSubmit={handleSubmit}>
                    <div className={'tableCellDiv'}>
                        <div className={'imageBlock'}>
                            {auth.user.avatar ?
                                <img alt={'My Photo'} src={serverImageUrl + auth.user.avatar} onClick={() => {
                                    filesRef.current.click();
                                }}/> :
                                <FontAwesomeIcon icon={'user'} size={'8x'} color={'#EAF6CD'} onClick={() => {
                                    filesRef.current.click();
                                }}/>}
                            {avatar && <span>Фото выбрано</span>}
                        </div>
                        <div>
                            {isEdit ? <div className={'formRow'} style={{
                                maxWidth: 337,
                            }}>
                                <p className={'formFieldName text-right'} style={{
                                    width: 61,
                                }}>Имя</p>
                                <input className={'formField'} defaultValue={auth.user.name} onChange={(text) => {
                                    setName(text.target.value);
                                }}/>
                            </div> : <div className={'formRow'} style={{
                                maxWidth: 337,
                            }}>
                                <p className={'formFieldName text-right'}>{auth.user.name}</p>
                            </div>}
                            {isEdit ? <div className={'formRow'} style={{
                                maxWidth: 337,
                                marginBottom: 0,
                            }}>
                                <p className={'formFieldName text-right'} style={{
                                    width: 61,
                                }}>Я</p>
                                <select className={'formField'} defaultValue={auth.user.category} onChange={(text) => {
                                    setRole(text.target.value);
                                }}>
                                    <option value={'student'}>Студент</option>
                                    <option value={'landscape_designer'}>Дизайнер</option>
                                    <option value={'gardener'}>Садовод</option>
                                </select>
                            </div> : <div className={'formRow'} style={{
                                maxWidth: 337,
                                marginBottom: 0,
                            }}>
                                <p className={'formFieldName text-right'}>
                                    {auth.user.category === 'student' ? 'Студент' :
                                        auth.user.category === 'landscape_designer' ? 'Дизайнер' : 'Садовод'}
                                </p>
                            </div>}
                        </div>
                    </div>
                    <div className={'formRow'}>
                        <p className={'formFieldName text-left'}>Год рождения</p>
                        {isEdit ?
                            <select className={'formField'} defaultValue={auth.user.bornYear} onChange={(text) => {
                                setBirth(text.target.value);
                            }} style={{
                                maxWidth: 284,
                            }}>
                                {oldOptionRender()}
                            </select> : <p className={'formFieldName text-right'}>
                                {auth.user.bornYear}
                            </p>}
                    </div>
                    <div className={'formRow'}>
                        <p className={'formFieldName text-left'}>Пол</p>
                        {isEdit ? <div>
                            <div className={'isMaleBtn ' + (isMale !== null ? (isMale ? 'active' : '') :
                                (auth.user.isMale ? 'active' : ''))} style={{
                                marginRight: 38,
                            }} onClick={() => {
                                setIsMale(true);
                            }}>М
                            </div>
                            <div className={'isMaleBtn ' + (isMale !== null ? (!isMale ? 'active' : '') :
                                (!auth.user.isMale ? 'active' : ''))} onClick={() => {
                                setIsMale(false);
                            }}>Ж
                            </div>
                        </div> : <p className={'formFieldName text-right'}>
                            {auth.user.isMale ? 'М' : 'Ж'}
                        </p>}
                    </div>
                    <div className={'formRow'}>
                        <p className={'formFieldName text-left'}>Город</p>
                        {isEdit ? <input className={'formField'} defaultValue={auth.user.city} onChange={(text) => {
                            setCity(text.target.value);
                        }} style={{
                            maxWidth: 384,
                        }}/> : <p className={'formFieldName text-right'}>
                            {auth.user.city}
                        </p>}
                    </div>
                    <div className={'formRow'}>
                        <p className={'formFieldName text-left'}>Email</p>
                        {isEdit ?
                            <input className={'formField'} type={'email'} defaultValue={email} onChange={(text) => {
                                setEmail(text.target.value);
                            }} style={{
                                maxWidth: 384,
                            }}/> : <p className={'formFieldName text-right'}>
                                {auth.user.email}
                            </p>}
                    </div>
                    {isSocial ? (auth.user.email.indexOf('vk.com') !== -1 ?
                        <div className={'socialIcon'}><FontAwesomeIcon icon={['fab', 'vk']}/></div> :
                        (auth.user.email.indexOf('fb.com') !== -1 ?
                            <div className={'socialIcon'}><FontAwesomeIcon icon={['fab', 'facebook-f']}
                                                                           className={'socialIcon'}/></div>
                            : <div className={'socialIcon'}><FontAwesomeIcon icon={['fab', 'apple']}
                                                                             className={'socialIcon'}/></div>)) : ''}
                    <input type={'file'} className={'noView'} ref={filesRef} onChange={(input) => {
                        setAvatar(input.target.files[0]);
                    }}/>

                    <button className={'profileFormBtn ' + (isSocial ? 'mt-2' : '')}
                            onClick={handleSubmit}>{!isEdit ? 'Изменить данные' : 'Сохранить'}</button>

                    <Link to={'/profile/password/change'}><p className={'text-right mt-3 blackColor'}><u>Изменить
                        пароль</u></p></Link>

                    {!auth.user.isConfirmed && <Link to={'#'} onClick={(e) => {
                        e.preventDefault();
                        handleConfirm();
                    }}><p className={'text-right mt-3 blackColor'}><u>Подтвердить адрес электронной почты</u></p></Link>}
                </form>
            </div>
        </div>
    </div>;
}

export default connect((state /*, ownProps*/) => {
    return {
        user: state,
    }
}, dispatch => ({
    onLogin: (data) => {
        dispatch({type: 'LOGIN', payload: data})
    },
}))(Profile);
