import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {months} from '../tools/data';

const FlowerFilter = function (props) {
    const [filter, setFilter] = useState(props.filter);
    const [frosts, setFrosts] = useState(props.frosts || {});
    const [humidities, setHumidities] = useState(props.humidities || {});
    const [colorList, setColors] = useState(props.colors || {});
    const [colorNames, setColorNames] = useState([]);

    const [heightIsOpen, setHeightIsOpen] = useState(true);
    const [monthsIsOpen, setMonthsIsOpen] = useState(true);
    const [colorIsOpen, setColorIsOpen] = useState(true);
    const [sunIsOpen, setSunIsOpen] = useState(true);
    const [frostIsOpen, setFrostIsOpen] = useState(true);
    const [humidityIsOpen, setHumidityIsOpen] = useState(true);
    const [isFavoriteData, setIsFavoriteData] = useState(props.isFavoriteData);

    useEffect(() => {
        if (props.frosts) setFrosts(props.frosts);
        if (props.colors) setColors(props.colors);
        if (props.humidities) setHumidities(props.humidities);
        if (props.colorNames) setColorNames(props.colorNames);
    }, [props.colorNames, props.colors, props.frosts, props.humidities]);
    useEffect(() => {
        setIsFavoriteData(props.isFavoriteData)
    }, [props.isFavoriteData])

    const renderFrosts = () => {
        if (!frostIsOpen) return null;

        let arr = [];
        if (frosts) {
            for (let i = frosts['1']; i <= frosts['2']; i++) {
                arr.push(<div key={i} className={'filterCheckbox'} onClick={() => {
                    let tmp = Object.assign({}, filter);
                    if (!Array.isArray(tmp.frostResistance)) tmp.frostResistance = [];
                    if (tmp.frostResistance.indexOf(i) > -1)
                        tmp.frostResistance.splice(tmp.frostResistance.indexOf(i), 1);
                    else {
                        if (tmp.frostResistance.length > 2) tmp.frostResistance.shift();
                        tmp.frostResistance.push(i);
                    }
                    if (tmp.frostResistance.length === 0) delete tmp.frostResistance;
                    setFilter(tmp);
                }}>
                    <div className={'customLittleCheckbox ' + (Array.isArray(filter.frostResistance) &&
                    filter.frostResistance.indexOf(i) > -1 ?
                        'active' : '')}>
                        <FontAwesomeIcon icon={'check'}/>
                    </div>
                    <span>{i}</span>
                </div>)
            }
        }

        return <div className={'filterParamContent'}>
            {arr}
        </div>;
    }

    return <div className={'flowerFilter'}>
        <p className={'filterHeader'}>Список Растений</p>
        <p className={'filterFavorite pointer'}
           onClick={props.isFavorite}>{!isFavoriteData ? 'Избранное' : 'Назад к списку'}</p>
        <form onSubmit={(e) => {
            e.preventDefault();
        }}>
            <input value={filter.name ?? ''} onChange={(e) => {
                let tmp = Object.assign({}, filter);
                tmp.name = e.target.value;
                setFilter(tmp);
            }} placeholder={'Поиск по названию'} className={'filterNameSearch'}/>
            <div className={'filterBlock'}>
                <div className={'filterParam'}>
                    <p className={'filterBlockPartHeader'} onClick={() => {
                        setHeightIsOpen(!heightIsOpen);
                    }}>
                        <FontAwesomeIcon icon={heightIsOpen ? 'chevron-down' : 'chevron-up'} color={'#818181'}
                                         className={'filterOpenIcon'}/>
                        Высота в период цветения (см)
                        <OverlayTrigger
                            placement={'bottom'}
                            overlay={<Tooltip id={`tooltip-bottom`}>
                                Высота цветоноса в период цветения
                            </Tooltip>}>
                            <FontAwesomeIcon icon={'question-circle'} style={{
                                marginLeft: 10,
                            }}/>
                        </OverlayTrigger>
                    </p>
                    {heightIsOpen && <div className={'filterParamContent'}>
                        <span>От</span>
                        <input value={filter.minHeight ?? ''} onChange={(e) => {
                            let tmp = Object.assign({}, filter);
                            if (e.target.value.length > 0) tmp.minHeight = e.target.value;
                            else if (tmp.minHeight) delete tmp.minHeight;
                            setFilter(tmp);
                        }} className={'filterMiniInput'}/>
                        <span>До</span>
                        <input value={filter.maxHeight ?? ''} onChange={(e) => {
                            let tmp = Object.assign({}, filter);
                            if (e.target.value.length > 0) tmp.maxHeight = e.target.value;
                            else if (tmp.maxHeight) delete tmp.maxHeight;
                            setFilter(tmp);
                        }} className={'filterMiniInput'}/>
                    </div>}
                </div>
                <div className={'filterParam'}>
                    <p className={'filterBlockPartHeader'} onClick={() => {
                        setMonthsIsOpen(!monthsIsOpen);
                    }}>
                        <FontAwesomeIcon icon={monthsIsOpen ? 'chevron-down' : 'chevron-up'} color={'#818181'}
                                         className={'filterOpenIcon'}/>
                        Пора цветения
                        <OverlayTrigger
                            placement={'bottom'}
                            overlay={
                                <Tooltip id={`tooltip-bottom`}>
                                    Месяц(а), когда цветет растение
                                </Tooltip>
                            }>
                            <FontAwesomeIcon icon={'question-circle'} style={{
                                marginLeft: 10,
                            }}/>
                        </OverlayTrigger>
                    </p>
                    {monthsIsOpen && <div className={'filterParamContent'}>
                        {months.map((month, index) => {
                            return <div key={index} className={'filterCheckbox'} onClick={() => {
                                let tmp = Object.assign({}, filter);
                                if (!filter.minMonth && !filter.maxMonth) {
                                    tmp.minMonth = month.number;
                                    tmp.maxMonth = month.number;
                                } else if (filter.minMonth === filter.maxMonth) {
                                    if (filter.maxMonth === month.number) {
                                        delete tmp.minMonth;
                                        delete tmp.maxMonth;
                                    } else if (month.number < tmp.minMonth) {
                                        tmp.minMonth = month.number;
                                    } else {
                                        tmp.maxMonth = month.number;
                                    }
                                } else {
                                    if (tmp.maxMonth === month.number) {
                                        tmp.maxMonth = month.number - 1;
                                    } else if (tmp.minMonth === month.number) {
                                        tmp.minMonth = month.number + 1;
                                    } else if (tmp.minMonth > month.number) {
                                        tmp.minMonth = month.number;
                                    } else if (tmp.maxMonth < month.number) {
                                        tmp.maxMonth = month.number;
                                    } else if (tmp.maxMonth > month.number && tmp.minMonth < month.number) {
                                        delete tmp.minMonth;
                                        delete tmp.maxMonth;
                                    }
                                }
                                setFilter(tmp);
                            }}>
                                <div className={'customLittleCheckbox ' + (filter && filter.maxMonth >= month.number
                                && filter.minMonth <= month.number ? 'active' : '')}>
                                    <FontAwesomeIcon icon={'check'}/>
                                </div>
                                <span>{month.name}</span>
                            </div>;
                        })}
                    </div>}
                </div>
                <div className={'filterParam'}>
                    <p className={'filterBlockPartHeader'} onClick={() => {
                        setColorIsOpen(!colorIsOpen);
                    }}>
                        <FontAwesomeIcon icon={colorIsOpen ? 'chevron-down' : 'chevron-up'} color={'#818181'}
                                         className={'filterOpenIcon'}/>
                        Окрас
                        <OverlayTrigger
                            placement={'bottom'}
                            overlay={<Tooltip id={`tooltip-bottom`}>
                                Оттенок цветка
                            </Tooltip>}>
                            <FontAwesomeIcon icon={'question-circle'} style={{
                                marginLeft: 10,
                            }}/>
                        </OverlayTrigger>
                    </p>
                    {colorIsOpen && <div className={'filterParamContent'}>
                        {Object.values(colorList).map((color, index) => {
                            if (color === null || color.toString().length === 0) return null;
                            let obj = colorNames.find(name => name.code === color);
                            let name = obj ? obj.name : 'Имя не найдено';
                            return <OverlayTrigger key={index}
                                                   placement="bottom"
                                                   overlay={<Tooltip id="button-tooltip-2">{name}</Tooltip>}
                            >
                                <div className={'colorCircle ' + (filter.color && filter.color.indexOf(color) > -1 ?
                                    'active' : '')} key={index}
                                     style={{backgroundColor: color ?? '#000000'}} onClick={() => {
                                    let tmp = Object.assign({}, filter);
                                    if (!Array.isArray(tmp.color)) tmp.color = [];
                                    if (tmp.color.indexOf(color) > -1) tmp.color.splice(tmp.color.indexOf(color), 1);
                                    else {
                                        if (tmp.color.length > 2) tmp.color.shift();
                                        tmp.color.push(color);
                                    }
                                    if (tmp.color.length === 0) delete tmp.color;
                                    setFilter(tmp);
                                }}/>
                            </OverlayTrigger>;
                        })}
                    </div>}
                </div>
                <div className={'filterParam'}>
                    <p className={'filterBlockPartHeader'} onClick={() => {
                        setSunIsOpen(!sunIsOpen);
                    }}>
                        <FontAwesomeIcon icon={sunIsOpen ? 'chevron-down' : 'chevron-up'} color={'#818181'}
                                         className={'filterOpenIcon'}/>
                        Освещение
                        <OverlayTrigger
                            placement={'bottom'}
                            overlay={<Tooltip id={`tooltip-bottom`}>
                                Наиболее благоприятная зона освещения
                            </Tooltip>}>
                            <FontAwesomeIcon icon={'question-circle'} style={{
                                marginLeft: 10,
                            }}/>
                        </OverlayTrigger>
                    </p>
                    {sunIsOpen && <div className={'filterParamContent'}>
                        <div className={'filterCheckbox'} onClick={() => {
                            let tmp = Object.assign({}, filter);
                            if (tmp.isSun) delete tmp.isSun;
                            else tmp.isSun = 1;
                            setFilter(tmp);
                        }}>
                            <div className={'customLittleCheckbox ' + (filter.isSun ? 'active' : '')}>
                                <FontAwesomeIcon icon={'check'}/>
                            </div>
                            <span>Солнце</span>
                        </div>
                        <div className={'filterCheckbox'} onClick={() => {
                            let tmp = Object.assign({}, filter);
                            if (tmp.isPenumbra) delete tmp.isPenumbra;
                            else tmp.isPenumbra = 1;
                            setFilter(tmp);
                        }}>
                            <div className={'customLittleCheckbox ' + (filter.isPenumbra ? 'active' : '')}>
                                <FontAwesomeIcon icon={'check'}/>
                            </div>
                            <span>Полутень</span>
                        </div>
                        <div className={'filterCheckbox'} onClick={() => {
                            let tmp = Object.assign({}, filter);
                            if (tmp.isShadow) delete tmp.isShadow;
                            else tmp.isShadow = 1;
                            setFilter(tmp);
                        }}>
                            <div className={'customLittleCheckbox ' + (filter.isShadow ? 'active' : '')}>
                                <FontAwesomeIcon icon={'check'}/>
                            </div>
                            <span>Тень</span>
                        </div>
                    </div>}
                </div>
                <div className={'filterParam'}>
                    <p className={'filterBlockPartHeader'} onClick={() => {
                        setFrostIsOpen(!frostIsOpen);
                    }}>
                        <FontAwesomeIcon icon={frostIsOpen ? 'chevron-down' : 'chevron-up'} color={'#818181'}
                                         className={'filterOpenIcon'}/>
                        Зона морозостойкости
                        <OverlayTrigger
                            placement={'bottom'}
                            overlay={<Tooltip id={`tooltip-bottom`}>
                                Климатические области земного шара, подходящие для выращивания тех или иных
                                видов растений. (См. в разделе "Видеоуроки")
                            </Tooltip>}>
                            <FontAwesomeIcon icon={'question-circle'} style={{
                                marginLeft: 10,
                            }}/>
                        </OverlayTrigger>
                    </p>
                    {renderFrosts()}
                </div>
                <div className={'filterParam'}>
                    <p className={'filterBlockPartHeader'} onClick={() => {
                        setHumidityIsOpen(!humidityIsOpen);
                    }}>
                        <FontAwesomeIcon icon={humidityIsOpen ? 'chevron-down' : 'chevron-up'} color={'#818181'}
                                         className={'filterOpenIcon'}/>
                        Влажность почвы
                        <OverlayTrigger
                            placement={'bottom'}
                            overlay={<Tooltip id={`tooltip-bottom`}>
                                Пояснение в разделе "Видеоуроки" - работа с фильтром.
                            </Tooltip>}>
                            <FontAwesomeIcon icon={'question-circle'} style={{
                                marginLeft: 10,
                            }}/>
                        </OverlayTrigger>
                    </p>
                    {humidityIsOpen && <div className={'filterParamContent'}>
                        {humidities && Object.values(humidities).map((item, index) => {
                            if (item === null || item.toString().length === 0) return null;
                            return <div key={index} className={'filterCheckbox'} onClick={() => {
                                let tmp = Object.assign({}, filter);
                                if (!tmp.humidity || tmp.humidity !== item) tmp.humidity = item;
                                else delete tmp.humidity;
                                setFilter(tmp);
                            }}>
                                <div className={'customLittleCheckbox ' + (filter.humidity === (item) ?
                                    'active' : '')}>
                                    <FontAwesomeIcon icon={'check'}/>
                                </div>
                                <span>{item}</span>
                            </div>;
                        })}
                    </div>}
                </div>

                <Button variant={'custom'} className={'green'} onClick={() => {
                    props.onUpdate(filter);
                }}>Применить</Button>
                <Button variant={'custom'} className={'white'} onClick={() => {
                    props.onUpdate({});
                    setFilter({});
                }} type={'reset'}>Сбросить</Button>
            </div>
        </form>
    </div>
;
}

export default connect((state /*, ownProps*/) =>
    {
        return {};
    }
, dispatch => (
    {
    }
))(FlowerFilter)
