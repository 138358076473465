import {connect} from "react-redux";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {createRef, useEffect, useState} from "react";
import request from "../tools/ajaxManager";
import {useAuth} from "../context/provideAuth";

const AdminLessonTr = function (props) {
    let auth = useAuth();
    const [data, setData] = useState(props.data);
    const [isEdit, setIsEdit] = useState(false);
    const [code, setCode] = useState(props.data.code);
    const [amount, setAmount] = useState(props.data.amount);
    const [typeDisc, setTypeDisc] = useState(props.data.discountType);
    const [typeProd, setTypeProd] = useState(props.data.productType);
    const [isMultiple, setIsMultiple] = useState(props.data.isMiltiple);

    useEffect(() => {
        setData(props.data);
        setIsMultiple(props.data.isMiltiple);
        setCode(props.data.code);
        setAmount(props.data.amount);
        setTypeProd(props.data.productType);
        setTypeDisc(props.data.discountType);
    }, [props.data]);

    function handleEdit() {
        if (auth.token) {
            let formData = {
                code: code,
                product_type: typeProd,
                discount_type: typeDisc,
                amount: amount,
                is_multiple: isMultiple,
                id: data.id,
            };

            request(
                '/discount/',
                'PUT',
                formData,
                {
                    "YT-AUTH-TOKEN": "YourTar " + auth.token,
                },
                (response) => {
                    setIsEdit(false);
                    setData(response.data);
                    setIsMultiple(response.data.isMultiple);
                    setCode(response.data.code);
                    setAmount(response.data.amount);
                    setTypeProd(response.data.productType);
                    setTypeDisc(response.data.discountType);
                },
                (error) => {
                    alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
                },
            );
        }
    }

    if (isEdit) {
        return <tr>
            <td>{props.num}</td>
            <td>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Кодовое слово
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setCode(e.target.value)}
                                      type="text" defaultValue={code}/>
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Скидка
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control onChange={(e) => setAmount(e.target.value)}
                                      type="number" defaultValue={amount} />
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Тип скидки
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" custom
                                      onChange={(e) => setTypeDisc(e.target.value)}>
                            <option disabled={true} value={null}>Не выбрана</option>
                            <option value={'percent'}>%</option>
                            <option value={'amount'}>рублей</option>
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Form.Group controlId="name">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Для тарифа
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" custom
                                      onChange={(e) => setTypeProd(e.target.value)}>
                            <option disabled={true} value={null}>Не выбрана</option>
                            <option value={1}>Тариф на 1 месяц</option>
                            <option value={2}>Тариф на полгода</option>
                            <option value={3}>Тариф на 1 год</option>
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
            </td>
            <td>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" checked={isMultiple} label={"Использовать много раз"} onChange={(e) => {
                        setIsMultiple(e.target.checked);
                    }}/>
                </Form.Group>
            </td>
            <td></td>
            <td>
                <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                    setIsEdit(false);
                    setCode(data.code);
                    setAmount(data.amount);
                    setIsMultiple(data.isMultiple);
                }}>
                    <FontAwesomeIcon icon={'undo'} className={'mr-2'}/>
                    Отмена
                </Button>
                <Button className={'text-right'} variant="success" size={'sm'}
                        onClick={() => handleEdit()}>
                    <FontAwesomeIcon icon={'check'} className={'mr-2'}/>
                    Сохранить
                </Button>
            </td>
        </tr>
    }

    return <tr >
        <td>{props.num + 1}</td>
        <td style={{maxWidth: 200, wordWrap: 'break-word'}}>{data.code}</td>
        <td style={{maxWidth: 200, wordWrap: 'break-word'}}>{data.amount} {data.discountType === 'percent' ? '%' : 'рублей'}</td>
        <td style={{maxWidth: 200, wordWrap: 'break-word'}}>{data.productType === 1 ? 'На месяц' : data.productType === 2 ? 'На полгода' : 'На год'}</td>
        <td>
            <Form.Group controlId="formBasicCheckbox">
            </Form.Group>
                <Form.Check type="checkbox" defaultChecked={isMultiple} label="Использовать много раз" disabled={true}/>
        </td>
        <td>
            <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" defaultChecked={data.isActive} label="Активна" disabled={true} />
            </Form.Group>
        </td>
        <td>
            <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                setIsEdit(true);
            }}>
                <FontAwesomeIcon icon={'pen'}/>
            </Button>
            <Button className={'text-right'} variant="danger" size={'sm'}
                    onClick={() => props.delete(props.num)}>
                <FontAwesomeIcon icon={'trash'}/>
            </Button>
        </td>
    </tr>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(AdminLessonTr)
