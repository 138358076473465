import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Modal, Container, Row, Col, OverlayTrigger, Tooltip, Carousel} from "react-bootstrap";
import ModalImage from "react-modal-image";
import {serverFlowerImageUrl} from "../tools/parameters";
import Close from '../images/modal_close.webp';
import Height from '../images/icon_flower_height.webp';
import Sun from '../images/icon_flower_sun.webp';
import Frost from '../images/icon_flower_frost.webp';
import Color from '../images/icon_flower_colors.webp';
import Ground from '../images/icon_flower_ground.webp';
import Note from '../images/icon_flower_note.webp';
import Bloom from '../images/icon_flower_bloom.webp';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MonthColors from "./monthColors";

const FlowerInfoModal = function (props) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [activeColor, setActiveColor] = useState(null);
    const [colorNames, setColorNames] = useState([]);

    useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(props.data)) {
            setData(props.data);
            setActiveColor(props.data.bloomColors[0]);
            setShow(true);
        }
        if (props.colorNames) setColorNames(props.colorNames);
    }, [props.data, props.colorNames]);
    useEffect(() => {
        if (props.count > 0) setShow(true);
    }, [props.count]);

    let bloom = [];
    if (data && data.bloomColors !== null) {
        if (typeof data.bloomColors === 'object')
            bloom = Object.values(data.bloomColors);
        else bloom = data.bloomColors;
    }

    return <Modal show={show} onHide={() => setShow(false)}>
        {data && <Modal.Body>
            <img src={Close} alt={'close'} onClick={() => setShow(false)} style={{
                float: 'right',
                width: 33,
                height: 33,
            }}/>
            <p style={{
                fontSize: 22,
                textAlign: 'center',
                color: '#000000',
                marginTop: 33,
                marginBottom: 29,
            }}>{data.name}<br/>({data.latName})</p>
            <Container fluid="sm">
                <Row>
                    {data.images.length > 0 && <Col md="4">
                        <Carousel indicators={false}>
                            {data.images.length > 1 ? data.images.map((image, key) => {
                                return <Carousel.Item key={key}>
                                    <ModalImage
                                        small={serverFlowerImageUrl + image}
                                        large={serverFlowerImageUrl + image}
                                        alt={data.name}
                                        className={'modalFlowerImagePreview'}
                                        hideDownload={true}
                                    />
                                </Carousel.Item>;
                            }) : <ModalImage
                                small={serverFlowerImageUrl + data.images[0]}
                                large={serverFlowerImageUrl + data.images[0]}
                                alt={data.name}
                                className={'modalFlowerImagePreview'}
                                hideDownload={true}
                            />}
                        </Carousel>
                    </Col>}
                    <Col md="8">
                        <div className={'flowerModalParam'}>
                            <img src={Bloom} alt={'param'}/>
                            <div className={'flowerModalParamValue'}>
                                <p>
                                    Цветение
                                    <OverlayTrigger
                                        placement={'bottom'}
                                        overlay={
                                            <Tooltip id={`tooltip-bottom`}>
                                                Месяц(а), когда цветет растение
                                            </Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon icon={'question-circle'} style={{
                                            marginLeft: 20,
                                        }}/>
                                    </OverlayTrigger>
                                </p>
                                <MonthColors activeColor={activeColor} data={data}/>
                            </div>
                        </div>
                        <div className={'flowerModalParam'}>
                            <img src={Height} alt={'param'}/>
                            <p>
                                Высота в период цветения
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={<Tooltip id={`tooltip-bottom`}>
                                        Высота цветоноса в период цветения
                                    </Tooltip>}>
                                    <FontAwesomeIcon icon={'question-circle'} style={{
                                        marginLeft: 20,
                                    }}/>
                                </OverlayTrigger>
                                <br/><span>{data.minHeight}см - {data.maxHeight}см</span></p>
                        </div>
                        <div className={'flowerModalParam'}>
                            <img src={Sun} alt={'param'}/>
                            <p>
                                Освещение
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={<Tooltip id={`tooltip-bottom`}>
                                        Наиболее благоприятная зона освещения
                                    </Tooltip>}>
                                    <FontAwesomeIcon icon={'question-circle'} style={{
                                        marginLeft: 20,
                                    }}/>
                                </OverlayTrigger>
                                <br/><span>
                    {data.isSun ? 'солнце' : ''}
                                {data.isSun && (data.isPenumbra || data.isShadow) ? ', ' : ''}
                                {data.isPenumbra ? 'полутень' : ''}
                                {(data.isSun || data.isPenumbra) && data.isShadow ? ', ' : ''}
                                {data.isShadow ? 'тень' : ''}
                </span></p>
                        </div>
                        <div className={'flowerModalParam'}>
                            <img src={Frost} alt={'param'}/>
                            <p>
                                Зона морозостойкости
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={<Tooltip id={`tooltip-bottom`}>
                                        Климатические области земного шара, подходящие для выращивания тех или иных
                                        видов растений (См. в разделе "Видеоуроки")
                                    </Tooltip>}>
                                    <FontAwesomeIcon icon={'question-circle'} style={{
                                        marginLeft: 20,
                                    }}/>
                                </OverlayTrigger>
                                <br/><span>{data.frostResistanceMin}</span></p>
                        </div>
                        <div className={'flowerModalParam'}>
                            <img src={Color} alt={'param'}/>
                            <div className={'flowerModalParamValue'}>
                                <p>
                                    Цвет
                                    <OverlayTrigger
                                        placement={'bottom'}
                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                            Оттенок цветка
                                        </Tooltip>}>
                                        <FontAwesomeIcon icon={'question-circle'} style={{
                                            marginLeft: 20,
                                        }}/>
                                    </OverlayTrigger>
                                </p>
                                {bloom.map((bc, key) => {
                                    let obj = colorNames.find(name => name.code === bc);
                                    let name = obj ? obj.name : 'Имя не найдено';
                                    return <OverlayTrigger key={key}
                                                           placement="bottom"
                                                           overlay={<Tooltip id="button-tooltip-2">{name}</Tooltip>}
                                    >
                                        <div onClick={() => {
                                            setActiveColor(bc);
                                        }} className={'colorCircle'} key={key}
                                             style={{backgroundColor: bc ?? '#000000'}}/>
                                    </OverlayTrigger>
                                })}
                            </div>
                        </div>
                        <div className={'flowerModalParam'}>
                            <img src={Ground} alt={'param'}/>
                            <p>Почва<br/><span>{data.ground}</span></p>
                        </div>
                        <div className={'flowerModalParam'}>
                            <img src={Note} alt={'param'}/>
                            <p>Примечание<br/><span>{data.note}</span></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>}
            </Modal>;
            }

            export default connect((state /*, ownProps*/) => {
            return {};
        }, dispatch => ({}))(FlowerInfoModal)
