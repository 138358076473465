import React, {useEffect, useState} from "react";
import AdBlock from "../../components/adBlock";
import {useParams} from 'react-router-dom';
import {useAuth} from "../../context/provideAuth";
import request from "../../tools/ajaxManager";
import ReactPlayer from 'react-player'
import {serverLessonUrl} from '../../tools/parameters';

const LessonScreen = function (props) {
    const [lesson, setLesson] = useState(null);
    const auth = useAuth();
    const {id} = useParams();

    useEffect(() => {
        getLesson();
    }, [auth.token]);

    const getLesson = (title = null) => {
        if (auth.token) {
            request(
                '/lesson/' + id,
                'GET',
                {},
                {
                    'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
                },
                async (response) => {
                    setLesson(response.data);
                },
                (err, code) => {
                    if (err.message) alert(err.message)
                    console.log('ERROR', err)
                }
            )
        }
    }

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            {lesson && <div className={'indexPage'}>
                <h3 className={'mb-5'}>{lesson.title}</h3>
                <div>
                    <ReactPlayer className={'ml-auto mr-auto'} controls={!(lesson.type === 'youtube')}
                                 url={lesson.type === 'youtube' ? lesson.link : serverLessonUrl + lesson.video }
                                 width={'100%'}  />
                </div>
                <p className={'ml-5 mr-5 text-center mt-5'}>{lesson.description}</p>
            </div>}
        </div>
    </div>;
}

export default LessonScreen;