import {Breadcrumb, Table} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {useEffect, useState} from "react";
import request from "../../tools/ajaxManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomPagination from "../../components/customPagination";

const AdminUsers = function (props) {
    // state
    let auth = useAuth();
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    // effects
    useEffect(() => {
        if (auth.token) request(
            '/admin/user/?offset=' + ((page - 1) * 30),
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                setUsers(response.users);
                setCount(response.count);
            },
            (error) => {
            },
        );
    }, [auth.token, page]);

    return <div className={'content'}>
        <h3>Список пользователей</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список пользователей</Breadcrumb.Item>
        </Breadcrumb>

        <Table striped bordered hover size="sm">
            <thead>
            <tr>
                <th>#</th>
                <th>Email</th>
                <th>Имя</th>
                <th>Пол</th>
                <th>Город</th>
                <th>Год рождения</th>
                <th>Категория</th>
            </tr>
            </thead>
            <tbody>
            {users.map((item, key) => { console.log(item)
                let male = item.isMale === null ? '-' :
                    item.isMale ? <FontAwesomeIcon icon={'male'}/> : <FontAwesomeIcon icon={'female'}/>;
                let category;
                switch (item.category) {
                    case 'student':
                        category = 'Студент';
                        break;
                    case 'landscape_designer':
                        category = 'Ландшафтный дизайнер';
                        break;
                    case 'gardener':
                        category = 'Садовод';
                        break;
                    default:
                        category = '-';
                }
                return <tr key={key}>
                    <td>{((page - 1) * 30) + (key + 1)}</td>
                    <td>{item.isConfirmed && <FontAwesomeIcon icon={'check'} className={'mr-2'}/>} {item.email}</td>
                    <td>{item.name ?? '-'}</td>
                    <td>{male}</td>
                    <td>{item.city ?? '-'}</td>
                    <td>{item.bornYear ?? '-'}</td>
                    <td>{category}</td>
                </tr>;
            })}
            </tbody>
        </Table>

        <CustomPagination update={setPage} count={Math.ceil(count / 30)} />
    </div>;
}

export default AdminUsers;
