import request from "./ajaxManager";

export const getFlowerbed = (id, token, callback) => {
    if (token)
        request(
            '/flowerbed/' + id,
            'GET',
            {},
            {
                'YT-AUTH-TOKEN': 'YourTar ' + token,
            },
            callback,
            (err) => {
                console.log('ERROR', err)
            }
        )
}