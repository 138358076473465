import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import VK from "react-vk";

import PublicLayout from "./layout/public";
import ProvideAuth from "./context/provideAuth";
import PublicRoute from "./components/customize/publicRoute";
import PrivateRoute from "./components/customize/privateRoute";
import PrivateLayout from "./layout/private";
import AdminRoute from "./components/customize/adminRoute";
import AdminLayout from "./layout/admin";

library.add(fab, fas)

function App() {
    return (
        <div className="App">
            <ProvideAuth>
                <Router>
                    <Switch>
                        <AdminRoute path="/admin">
                            <AdminLayout />
                        </AdminRoute>
                        <PrivateRoute path="/flower*">
                            <PrivateLayout />
                        </PrivateRoute>
                        <PrivateRoute path="/profile">
                            <PrivateLayout />
                        </PrivateRoute>
                        <PrivateRoute path="/service">
                            <PrivateLayout />
                        </PrivateRoute>
                        <PublicRoute path="/">
                            <VK apiId={7784134} >
                                <PublicLayout />
                            </VK>
                        </PublicRoute>
                    </Switch>
                </Router>
            </ProvideAuth>
        </div>
    );
}

export default App;
