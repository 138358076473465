import React, {useState} from "react";
import AdBlock from "../../components/adBlock";
import {useHistory, useLocation} from 'react-router-dom';
import {Button} from "react-bootstrap";
import {useAuth} from "../../context/provideAuth";
import request from "../../tools/ajaxManager";

const FeedbackScreen = function (props) {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [content, setContent] = useState('');
    const auth = useAuth();
    let history = useHistory();
    let location = useLocation();
    const [isSocial] = useState(auth.user.email.indexOf('vk.com') !== -1 ||
        auth.user.email.indexOf('fb.com') !== -1);
    const [isTech, setIsTech] = useState(location.pathname === '/service/tech');

    const handleSend = (e) => {
        e.preventDefault();

        let data = new FormData();
        if (email.length > 0) data.append('email', email);
        data.append('content', content);
        if (phone.length > 0) data.append('phone', phone);

        request(
            '/feedback/',
            'POST',
            data,
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {
                history.push('/service');
            },
            (err) => {
                console.log('ERROR', err)
            }
        );
    }

    return <div className={'contentLayout'}>
        <div className={'leftSidePart'}>
            <AdBlock/>
        </div>
        <div className={'rightSidePart'}>
            <div className={'indexPage'}>
                <div className={'card1'}>
                    <div>
                        <div className={'card1Tab'}>
                            <span>Обратная связь</span>
                        </div>
                    </div>
                    <div className={'divider'}/>
                    <div className={'loginForm'}>
                        <form onSubmit={handleSend}>
                            <textarea onChange={(e) => setContent(e.target.value)}
                                      placeholder={isTech ? "Ваш вопрос или предложение" :
                                          "Ваш вопрос или описание проекта"}/>
                            {!isTech && <>
                                <input onChange={(e) => setEmail(e.target.value)}
                                       defaultValue={!isSocial ? auth.user.email : ''} type="email"
                                       placeholder="Адрес электронной почты"/>
                                <input onChange={(e) => setPhone(e.target.value)}
                                       type={'phone'} placeholder="Телефон"/>
                            </>}

                            <Button onClick={handleSend} variant="custom" type="submit">
                                Отправить
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default FeedbackScreen;