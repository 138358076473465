//prod
const server = 'https://xn--80aqu.xn--80aaccp5ahifj7br4as6j.xn--p1ai/';
// xn--80aaccp5ahifj7br4as6j.xn--p1ai

//stage
// const server = 'https://flowerbed-back.vladimirov-mv.name/';

export const serverUrl = server + 'api';
export const serverFlowerImageUrl = server + 'upload/flowers/';
export const serverImageUrl = server + 'upload/images/';
export const serverLessonUrl = server + 'upload/files/lessons/';
export const serverAdUrl = server + 'upload/images/ads/';
