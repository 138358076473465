import {Breadcrumb, Form, Table, Button, Col} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {createRef, useEffect, useState} from "react";
import request from "../../tools/ajaxManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {serverFlowerImageUrl} from "../../tools/parameters";

const AdminImages = function (props) {
    // state
    let auth = useAuth();
    const [list, setList] = useState([]);

    const filesRef = createRef();

    // effects
    useEffect(() => {
        if (auth.token) request(
            '/flower/images',
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                setList(Object.values(response.data));
            },
            (error) => {
            },
        );
    }, [auth.token]);

    const addImages = function () {
        let files = filesRef.current.files, data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append('files[]', files[i]);
        }

        request(
            '/flower/images',
            'POST',
            data,
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...list];
                setList(tmp.concat(response.data))
            },
            (error) => {
            },
        );
    }
    const removeImages = function (key) {
        let data = {
            name: list[key],
        }

        request(
            '/flower/images',
            'DELETE',
            data,
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...list];
                tmp.splice(key, 1);
                setList(tmp);
            },
            (error) => {
            },
        );
    }

    return <div className={'content'}>
        <h3>Список фотографий растений</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список фотографий растений</Breadcrumb.Item>
        </Breadcrumb>

        <Form>
            <Form.Row>
                <Col>
                    <Form.Control placeholder="Выберите файлы" type={'file'} ref={filesRef} multiple={true}/>
                </Col>
                <Col>
                    <Button onClick={addImages}>Загрузить</Button>
                </Col>
            </Form.Row>
        </Form>
        <br/>

        <Table striped bordered hover size="sm">
            <thead>
            <tr>
                <th>#</th>
                <th>Название</th>
                <th>Фото</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {list.map((item, key) => {
                return <tr key={key}>
                    <td>{(key + 1)}</td>
                    <td>{item}</td>
                    <td>
                        <img src={serverFlowerImageUrl + item} style={{
                            width: 200,
                            height: 200,
                            objectFit: 'contain',
                        }}/>
                    </td>
                    <td>
                        <Button variant={'danger'} onClick={() => removeImages(key)}>
                            <FontAwesomeIcon icon={'trash'}/>
                        </Button>
                    </td>
                </tr>;
            })}
            </tbody>
        </Table>
    </div>;
}

export default AdminImages;