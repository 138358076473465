import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef, useState} from "react";
import {Button, Alert} from "react-bootstrap";
import {useAuth} from "../context/provideAuth";
import request from "../tools/ajaxManager";
import CanvasDraw from "react-canvas-draw";
import useWindowSize from "../hooks/useWindowSize";

const FlowerbedFormCanvas = function (props) {
    const auth = useAuth();
    const [flowerbed, setFlowerbed] = useState(props.data);
    const [countToTariff, setCountToTariff] = useState(0);
    const size = useWindowSize();

    let canvasRef = useRef();

    useEffect(() => {
        setFlowerbed(props.data);
    }, [props.data]);

    const apiItem = async () => {
        let method = 'POST';
        let formData = new FormData();
        formData.append('form', canvasRef.current.canvas.drawing.toDataURL("image/png"));

        request(
            '/flowerbed/' + flowerbed.id + '/draw',
            method,
            formData,
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {
                setFlowerbed(response.data)
                props.update(response.data)
            },
            (err, code) => {
                if (code === 403) setCountToTariff(countToTariff + 1);
                console.log('ERROR', err)
            }
        )
    };

    const tableRender = (month = null) => {
        let heightVar = flowerbed.height > flowerbed.width ? flowerbed.width / flowerbed.height
            : flowerbed.height / flowerbed.width;

        return <div className={'position-relative mr-auto ml-auto d-inline-block'}>
            <div className={'mb-3'}>
                <Button className={'mr-1'} variant="customLight" onClick={() => canvasRef.current.clear()}>
                    <FontAwesomeIcon icon={'trash'}/>
                </Button>
                <Button className={'mr-1'} variant="customLight" onClick={() => canvasRef.current.undo()}>
                    <FontAwesomeIcon icon={'undo'}/>
                </Button>
                <Button variant="customDark" onClick={() => {
                    apiItem();
                }}>
                    <FontAwesomeIcon icon={'save'}/>
                </Button>
            </div>
            <div className={'mr-auto ml-auto d-inline-block'}>
                <CanvasDraw canvasWidth={(size.width > 767 ? 400 : size.width - 80)
                * (flowerbed.height > flowerbed.width ? heightVar : 1)}
                            canvasHeight={(size.width > 767 ? 400 : size.width - 80)
                            * (flowerbed.height > flowerbed.width ? 1 : heightVar)}
                            lazyRadius={15}
                            brushRadius={1}
                            brushColor={"#000000"}
                            catenaryColor={"#0a0302"}
                            gridColor={"#EAF6CD"}
                            hideGrid={false} style={{
                    backgroundColor: '#82B21E',
                    border: 'solid 1px #EAF6CD',
                }} ref={canvasRef}/>
            </div>
        </div>;
    }

    return <div className={'flowerbedMainForm'}>
        <Alert variant={'custom mt-3 mb-3'}>
            Нарисуйте мышью форму клумбы для дальнейшего заполнения.
        </Alert>
        <div>
            {tableRender()}
        </div>
    </div>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(FlowerbedFormCanvas)