import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row, Dropdown, Alert, Tooltip, OverlayTrigger} from "react-bootstrap";
import useMousePosition from "../hooks/useMousePosition";
import {useAuth} from "../context/provideAuth";
import request from "../tools/ajaxManager";
import html2canvas from "html2canvas";
import {months} from "../tools/data";
import {serverImageUrl} from '../tools/parameters';
import useWindowSize from "../hooks/useWindowSize";

import logo from '../images/icon.png';
import flowerPlaceholder from '../images/flowerPlaceholder.webp';
import Grid from '../images/grid.webp';

const placeholderFlowerColor = '#A2B6A2';

const FlowerbedForm = function (props) {
    const auth = useAuth();
    const {x, y} = useMousePosition();
    const size = useWindowSize();
    const [countToTariff, setCountToTariff] = useState(0);
    const [flowerbed, setFlowerbed] = useState(props.data);
    const [currentFlower, setCurrentFlower] = useState(null);
    const [currentColor, setCurrentColor] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(5);
    const [ratio, setRatio] = useState(1);
    const [customDiameter, setCustomDiameter] = useState(null);
    const [scale, setScale] = useState(1);
    const [northSelect, setNorthSelect] = useState(false);
    const [blockSize, setBlockSize] = useState(10);
    const [colorNames, setColorNames] = useState([]);

    let formRef = useRef();
    let selectRef = useRef();

    useEffect(() => {
        request(
            '/color/',
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                setColorNames(response.data);
            },
            (error) => {
            },
        );
    }, []);
    useEffect(() => {
        setFlowerbed(props.data);
    }, [props.data]);
    useEffect(() => {
        let blockCount = Math.ceil((flowerbed.height > flowerbed.width ? flowerbed.height : flowerbed.width)
            / blockSize);
        let blockWidth = (size.width > 1024 ? 450
            : size.width > 767 ? (size.width - 520) : (size.width - 40)) / (blockCount);
        let workWidth = blockWidth * (blockCount);
        setRatio(flowerbed ?
            ((flowerbed.width < workWidth
                && flowerbed.height < workWidth) ? (flowerbed.width < flowerbed.height ?
                workWidth / flowerbed.height
                : workWidth / flowerbed.width)
                : (flowerbed.width < flowerbed.height ?
                    workWidth / flowerbed.height
                    : workWidth / flowerbed.width)) : 1);
    }, [size.width, blockSize, flowerbed])

    const apiItem = async (event, type = 'add') => {
        let formData, method;

        let blockCount = Math.ceil((flowerbed.height > flowerbed.width ? flowerbed.height : flowerbed.width)
            / blockSize);
        let blockWidth = ((flowerbed.height > flowerbed.width ? flowerbed.height : flowerbed.width) * ratio) / blockCount;

        switch (type) {
            case 'add':
            default:
                method = 'POST';
                formData = new FormData();
                formData.append('color', currentColor);
                formData.append('top', x - formRef.current.offsetLeft -
                    ((parseInt(currentFlower) !== -1 ? flowerbed.flowers[currentFlower].diameter : customDiameter)
                        * ratio / 2) - blockWidth);
                formData.append('left', y - formRef.current.offsetTop -
                    ((parseInt(currentFlower) !== -1 ? flowerbed.flowers[currentFlower].diameter : customDiameter)
                        * ratio / 2) - blockWidth);
                if (parseInt(currentFlower) !== -1)
                    formData.append('flower_id', flowerbed.flowers[currentFlower].id);
                else formData.append('custom_diameter', customDiameter);
                formData.append('ratio', ratio);
                break;
            case 'edit':
                let diameter = ((flowerbed.flowerbedItems[activeItem].flower
                    ? flowerbed.flowerbedItems[activeItem].flower.diameter : flowerbed.flowerbedItems[activeItem].customDiameter)
                    * ratio);
                method = 'POST';
                formData = new FormData();
                let top = x - formRef.current.offsetLeft -
                    (diameter / 2) - blockWidth;
                if (top < 0) top = 0;
                if (top > ((Math.ceil(flowerbed.width / blockSize) + 2) * blockWidth + 1) - diameter)
                    top = top - (top - (((Math.ceil(flowerbed.width / blockSize) + 2) * blockWidth + 1) - diameter - (2 * blockWidth)));
                formData.append('top', top);
                let left = y - formRef.current.offsetTop -
                    (diameter / 2) - blockWidth;
                if (left < 0) left = 0;
                if (left > ((Math.ceil(flowerbed.height / blockSize) + 2) * blockWidth + 1) - diameter)
                    left = left - (left - (((Math.ceil(flowerbed.height / blockSize) + 2) * blockWidth + 1) - diameter - (2 * blockWidth)));
                formData.append('left', left);
                formData.append('ratio', ratio);

                break;
            case 'delete':
                method = 'DELETE';
                formData = {};
                break;
            case 'preview':
                let preview = await html2canvas(document.getElementById('flowerbedFormMAIN'));
                method = 'POST';
                formData = new FormData();
                formData.append('preview', preview.toDataURL('image/png', 0.5));
                break;
        }

        request(
            '/flowerbed/' + flowerbed.id + '/item' + (type !== 'add' ? '/' + flowerbed.flowerbedItems[type !== 'preview' ? activeItem : 0].id : ''),
            method,
            formData,
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {
                setFlowerbed(response.data)
                switch (type) {
                    case 'delete':
                        setActiveItem(null);
                        break;
                    case 'add':
                    case 'edit':
                        apiItem(null, 'preview')
                        break;
                }
            },
            (err, code) => {
                if (code === 403) setCountToTariff(countToTariff + 1);
                console.log('ERROR', err)
            }
        )
    };
    const handlePrint = async () => {
        request(
            '/user/checkTariff',
            'GET',
            {},
            {
                'YT-AUTH-TOKEN': 'YourTar ' + auth.token,
            },
            async (response) => {
                if (response.active) {
                    await setScale(1);
                    await setNorthSelect(false)
                    let html = document.getElementsByClassName('print-content')[0];
                    if (!response.isTest) {
                        html.querySelectorAll(".watermark").forEach(el => el.remove());
                    }
                    html = html.innerHTML;
                    let w = window.open();
                    let content = '<html><head>' +
                        '</head><body>' +
                        html +
                        '</body></html>';
                    w.document.write(content);
                    w.print();
                    w.close();
                } else alert('Для печати продлите подписку на приложение. Сделать это можно в разделе "Сервисы" -> "Тарифы"')
            },
            (err) => {
                console.log('ERROR', err)
            }
        )
    }

    const tableRender = (month = null) => {
        let blockCount = Math.ceil((flowerbed.height > flowerbed.width ? flowerbed.height : flowerbed.width)
            / blockSize);
        let blockWidth = ((flowerbed.height > flowerbed.width ? flowerbed.height : flowerbed.width) * ratio)
            / blockCount;

        return <div>
            <div>
                <span style={{
                    display: 'inline-block',
                    width: 15,
                    height: 15,
                    border: 'solid 1px #EAF6CD',
                    marginRight: 10,
                    marginTop: 25,
                }}/>
                <span className={'h6 m-0'}>- размер сетки 10 см х 10 см</span>
            </div>
            <div style={{
                minWidth: (Math.ceil(flowerbed.width / blockSize) + 2) * blockWidth + 1,
                minHeight: (Math.ceil(flowerbed.height / blockSize) + 2) * blockWidth + 1 + 30,
                maxWidth: (size.width > 1024 ? 450
                    : size.width > 767 ? (size.width - 520) : (size.width - 40)),
                maxHeight: (size.width > 1024 ? 450
                    : size.width > 767 ? (size.width - 520) : (size.width - 40)),
                margin: '20px auto 0 auto',
                overflow: 'auto',
                backgroundImage: 'url(' + Grid + ')',
                backgroundSize: (blockWidth * 5 * (month ? 1 : scale)) + 'px ' + (blockWidth * 5 * (month ? 1 : scale)) + 'px',
                backgroundRepeat: 'repeat',
            }}>
                <div style={{
                    width: (Math.ceil(flowerbed.width / blockSize) + 2) * blockWidth * (month ? 1 : scale) + 1,
                    height: (Math.ceil(flowerbed.height / blockSize) + 2) * blockWidth * (month ? 1 : scale) + 1,
                    maxWidth: '100%',
                    marginLeft: (Math.ceil(flowerbed.width / blockSize) + 2) * blockWidth * ((month ? 1 : scale) - 1) / 2,
                    marginTop: (Math.ceil(flowerbed.height / blockSize) + 2) * blockWidth * ((month ? 1 : scale) - 1) / 2,
                }}>
                    <div key={'flowerbedForm' + (month ? month : 'MAIN')}
                         id={'flowerbedForm' + (month ? month : 'MAIN')} ref={month === null ? formRef : null}
                         className={'flowerbedForm'} style={Object.assign({}, {
                        width: (Math.ceil(flowerbed.width / blockSize) + 2) * blockWidth + 1,
                        height: (Math.ceil(flowerbed.height / blockSize) + 2) * blockWidth + 1,
                        maxWidth: '100%',
                        padding: 0,
                        position: "relative",
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        transform: 'scale(' + (month ? 1 : scale) + ')',
                    }, flowerbed.customFormImage ? {
                        backgroundImage: 'url(' + (serverImageUrl + flowerbed.customFormImage) + ')',
                        backgroundSize: ((Math.ceil(flowerbed.width / blockSize) + 2) * blockWidth + 1) + 'px '
                            + ((Math.ceil(flowerbed.height / blockSize) + 2) * blockWidth + 1) + 'px',
                        backgroundRepeat: 'no-repeat',
                    } : {})} onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (currentFlower && currentColor && activeItem === null) {
                            apiItem(e);
                        } else if (activeItem !== null) {
                            apiItem(e, 'edit');
                        }
                    }}>
                        {/*{rows}*/}
                        {(northSelect || flowerbed.north === null || flowerbed.north === 'u') && <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip
                                id="button-tooltip-2">{northSelect
                                ? 'Кликните для выбора направления севера'
                                : 'Север там'}</Tooltip>}>
                            <span onClick={() => {
                                setNorthSelect(!northSelect);
                                if (northSelect) props.update('u')
                                let tmp = flowerbed;
                                tmp.north = 'u';
                                setFlowerbed(tmp);
                            }} style={Object.assign({}, {
                                position: "absolute",
                                top: 0,
                                left: (Math.ceil(flowerbed.width / blockSize) + 2) * blockWidth / 2 - 4,
                                fontSize: 12,
                            }, (northSelect ? {
                                backgroundColor: '#ffffff77',
                                borderRadius: '50%',
                                padding: 2,
                            } : {}))}><FontAwesomeIcon icon={northSelect ? 'hand-pointer' : 'arrow-up'} style={{
                                width: 12,
                                height: 12,
                                fontSize: 12,
                                marginRight: 5,
                                display: 'inline',
                            }}/>N</span>
                        </OverlayTrigger>}
                        {(northSelect || flowerbed.north === 'lu') && <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip
                                id="button-tooltip-2">{northSelect
                                ? 'Кликните для выбора направления севера'
                                : 'Север там'}</Tooltip>}>
                            <span onClick={() => {
                                setNorthSelect(!northSelect);
                                if (northSelect) props.update('lu')
                                let tmp = flowerbed;
                                tmp.north = 'lu';
                                setFlowerbed(tmp);
                            }} style={Object.assign({}, {
                                position: "absolute",
                                top: 5,
                                left: 0,
                                fontSize: 12,
                                transform: `rotate(-45deg)`,
                            }, (northSelect ? {
                                backgroundColor: '#ffffff77',
                                borderRadius: '50%',
                                padding: 2,
                            } : {}))}><FontAwesomeIcon icon={northSelect ? 'hand-pointer' : 'arrow-up'} style={{
                                width: 12,
                                height: 12,
                                fontSize: 12,
                                marginRight: 5,
                                display: 'inline',
                            }}/>N</span>
                        </OverlayTrigger>}
                        {(northSelect || flowerbed.north === 'ur') && <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip
                                id="button-tooltip-2">{northSelect
                                ? 'Кликните для выбора направления севера'
                                : 'Север там'}</Tooltip>}>
                            <span onClick={() => {
                                setNorthSelect(!northSelect);
                                if (northSelect) props.update('ur')
                                let tmp = flowerbed;
                                tmp.north = 'ur';
                                setFlowerbed(tmp);
                            }} style={Object.assign({}, {
                                position: "absolute",
                                top: 5,
                                right: 0,
                                fontSize: 12,
                                transform: `rotate(45deg)`,
                            }, (northSelect ? {
                                backgroundColor: '#ffffff77',
                                borderRadius: '50%',
                                padding: 2,
                            } : {}))}><FontAwesomeIcon icon={northSelect ? 'hand-pointer' : 'arrow-up'} style={{
                                width: 12,
                                height: 12,
                                fontSize: 12,
                                marginRight: 5,
                                display: 'inline',
                            }}/>N</span>
                        </OverlayTrigger>}
                        {(northSelect || flowerbed.north === 'd') && <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip
                                id="button-tooltip-2">{northSelect
                                ? 'Кликните для выбора направления севера'
                                : 'Север там'}</Tooltip>}>
                            <span onClick={() => {
                                setNorthSelect(!northSelect);
                                if (northSelect) props.update('d')
                                let tmp = flowerbed;
                                tmp.north = 'd';
                                setFlowerbed(tmp);
                            }} style={Object.assign({}, {
                                position: "absolute",
                                bottom: 0,
                                left: (Math.ceil(flowerbed.width / blockSize) + 2) * blockWidth / 2 - 4,
                                fontSize: 12,
                                transform: `rotate(180deg)`,
                            }, (northSelect ? {
                                backgroundColor: '#ffffff77',
                                borderRadius: '50%',
                                padding: 2,
                            } : {}))}><FontAwesomeIcon icon={northSelect ? 'hand-pointer' : 'arrow-up'} style={{
                                width: 12,
                                height: 12,
                                fontSize: 12,
                                marginRight: 5,
                                display: 'inline',
                            }}/>N</span>
                        </OverlayTrigger>}
                        {(northSelect || flowerbed.north === 'dr') && <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip
                                id="button-tooltip-2">{northSelect
                                ? 'Кликните для выбора направления севера'
                                : 'Север там'}</Tooltip>}>
                            <span onClick={() => {
                                setNorthSelect(!northSelect);
                                if (northSelect) props.update('dr')
                                let tmp = flowerbed;
                                tmp.north = 'dr';
                                setFlowerbed(tmp);
                            }} style={Object.assign({}, {
                                position: "absolute",
                                bottom: 5,
                                right: 0,
                                fontSize: 12,
                                transform: `rotate(135deg)`,
                            }, (northSelect ? {
                                backgroundColor: '#ffffff77',
                                borderRadius: '50%',
                                padding: 2,
                            } : {}))}><FontAwesomeIcon icon={northSelect ? 'hand-pointer' : 'arrow-up'} style={{
                                width: 12,
                                height: 12,
                                fontSize: 12,
                                marginRight: 5,
                                display: 'inline',
                            }}/>N</span>
                        </OverlayTrigger>}
                        {(northSelect || flowerbed.north === 'ld') && <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip
                                id="button-tooltip-2">{northSelect
                                ? 'Кликните для выбора направления севера'
                                : 'Север там'}</Tooltip>}>
                            <span onClick={() => {
                                setNorthSelect(!northSelect);
                                if (northSelect) props.update('ld')
                                let tmp = flowerbed;
                                tmp.north = 'ld';
                                setFlowerbed(tmp);
                            }} style={Object.assign({}, {
                                position: "absolute",
                                bottom: 5,
                                left: 0,
                                fontSize: 12,
                                transform: `rotate(-135deg)`,
                            }, (northSelect ? {
                                backgroundColor: '#ffffff77',
                                borderRadius: '50%',
                                padding: 2,
                            } : {}))}><FontAwesomeIcon icon={northSelect ? 'hand-pointer' : 'arrow-up'} style={{
                                width: 12,
                                height: 12,
                                fontSize: 12,
                                marginRight: 5,
                                display: 'inline',
                            }}/>N</span>
                        </OverlayTrigger>}
                        {(northSelect || flowerbed.north === 'l') && <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip
                                id="button-tooltip-2">{northSelect
                                ? 'Кликните для выбора направления севера'
                                : 'Север там'}</Tooltip>}>
                            <span onClick={() => {
                                setNorthSelect(!northSelect);
                                if (northSelect) props.update('l')
                                let tmp = flowerbed;
                                tmp.north = 'l';
                                setFlowerbed(tmp);
                            }} style={Object.assign({}, {
                                position: "absolute",
                                bottom: (Math.ceil(flowerbed.height / blockSize) + 2) * blockWidth / 2 - 4,
                                left: 0,
                                fontSize: 12,
                                transform: `rotate(-90deg)`,
                            }, (northSelect ? {
                                backgroundColor: '#ffffff77',
                                borderRadius: '50%',
                                padding: 2,
                            } : {}))}><FontAwesomeIcon icon={northSelect ? 'hand-pointer' : 'arrow-up'} style={{
                                width: 12,
                                height: 12,
                                fontSize: 12,
                                marginRight: 5,
                                display: 'inline',
                            }}/>N</span>
                        </OverlayTrigger>}
                        {(northSelect || flowerbed.north === 'r') && <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip
                                id="button-tooltip-2">{northSelect
                                ? 'Кликните для выбора направления севера'
                                : 'Север там'}</Tooltip>}>
                            <span onClick={() => {
                                setNorthSelect(!northSelect);
                                if (northSelect) props.update('r')
                                let tmp = flowerbed;
                                tmp.north = 'r';
                                setFlowerbed(tmp);
                            }} style={Object.assign({}, {
                                position: "absolute",
                                bottom: (Math.ceil(flowerbed.height / blockSize) + 2) * blockWidth / 2 - 4,
                                right: 0,
                                fontSize: 12,
                                transform: `rotate(90deg)`,
                            }, (northSelect ? {
                                backgroundColor: '#ffffff77',
                                borderRadius: '50%',
                                padding: 2,
                            } : {}))}><FontAwesomeIcon icon={northSelect ? 'hand-pointer' : 'arrow-up'} style={{
                                width: 12,
                                height: 12,
                                fontSize: 12,
                                marginRight: 5,
                                display: 'inline',
                            }}/>N</span>
                        </OverlayTrigger>}
                        <div style={{
                            border: 'solid 1px ' + (flowerbed.form === 'custom' ? 'grey' : '#000000'),
                            height: (Math.ceil(flowerbed.height / blockSize)) * (blockWidth),
                            width: (Math.ceil(flowerbed.width / blockSize)) * (blockWidth),
                            position: 'absolute',
                            borderRadius: flowerbed.form === 'square' || flowerbed.form === 'custom' ? 0 : '50%',
                            top: blockWidth,
                            left: blockWidth,
                        }}/>
                        {flowerbed.flowerbedItems.map((item, key) => {
                            return <div key={item.id} className={'flowerbedItem'} style={{
                                height: ((item.flower ? item.flower.diameter : item.customDiameter) * ratio),
                                width: ((item.flower ? item.flower.diameter : item.customDiameter) * ratio),
                                backgroundColor: (item.flower ? (((month ?? currentMonth) <= item.flower.maxMonth && (month ?? currentMonth) >= item.flower.minMonth) ?
                                    item.color : item.flower.monthColors[(month ?? currentMonth) - 1]) : item.color),
                                colorAdjust: 'exact',
                                borderRadius: ((item.flower ? item.flower.diameter : item.customDiameter) * ratio / 2),
                                display: 'inline-block',
                                position: 'absolute',
                                left: (blockWidth) + item.top * (ratio / item.ratio),
                                top: (blockWidth) + item.leftPosition * (ratio / item.ratio),
                                border: activeItem === key ? 'solid 5px #ffffff77' : 'solid ' + (item.flower ? 1 : 0) + 'px black',
                                zIndex: 5,
                                fontSize: 14,
                                textAlign: 'center',
                                verticalAlign: 'middle',
                            }} onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (activeItem === key) setActiveItem(null);
                                else setActiveItem(key)
                                setCurrentColor(null);
                                setCurrentFlower(null);
                                selectRef.current.value = null;
                            }}>
                                {item.flower ? <span style={{
                                        lineHeight: ((item.flower ? item.flower.diameter : item.customDiameter) * ratio) + 'px',
                                        fontSize: 18,
                                    }}>{(flowerbed.flowers.findIndex(flower => flower.id === item.flower.id) + 1)}</span> :
                                    <img src={flowerPlaceholder} style={{
                                        width: ((item.flower ? item.flower.diameter : item.customDiameter) * ratio) - ((activeItem === key ? 5 : 0) * 2),
                                        height: ((item.flower ? item.flower.diameter : item.customDiameter) * ratio) - ((activeItem === key ? 5 : 0) * 2),
                                        objectFit: 'contain',
                                    }}/>}
                            </div>
                        })}
                        <div className={'watermark'} style={{
                            width: (Math.ceil(flowerbed.width / blockSize) + 2) * (blockWidth) + 1,
                            height: (Math.ceil(flowerbed.height / blockSize) + 2) * (blockWidth) + 1,
                            maxWidth: '100%',
                            position: 'absolute',
                            // margin: '20px auto 0 auto',
                            overflow: 'auto',
                            opacity: .4,
                            zIndex: 1000,
                        }}>
                            <div style={{
                                width: (Math.ceil((flowerbed.width > flowerbed.height
                                    ? flowerbed.height
                                    : flowerbed.width) / blockSize) + 2) * blockWidth / 2,
                                height: (Math.ceil((flowerbed.width > flowerbed.height
                                    ? flowerbed.height
                                    : flowerbed.width) / blockSize) + 2) * blockWidth / 2,
                                marginTop: '7%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}>
                                <img src={logo} style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}/>
                            </div>
                            <p style={{
                                textAlign: 'center',
                            }}>Flower Garden<br/>Пробная версия</p>
                        </div>
                    </div>
                </div>
            </div>
            {!month && <span className={'pointer ml-1 mr-1 d-inline-block'} onClick={(e) => {
                setScale(scale >= 15 ? 15 : scale + 1)
            }}>Увеличить <FontAwesomeIcon icon={'plus-square'} className={'noPrint'}/></span>}
            {!month && <span className={'pointer ml-1 mr-1 d-inline-block'} onClick={(e) => {
                setScale(scale <= 1 ? 1 : scale - 1)
            }}>Уменьшить <FontAwesomeIcon icon={'minus-square'} className={'noPrint'}/></span>}
            {activeItem !== null && !month &&
            <FontAwesomeIcon onClick={(e) => apiItem(e, 'delete')}
                             icon={'trash'} className={'noPrint pointer ml-1 mr-1'}/>}
        </div>;
    }
    const listRender = () => {
        let liArray = {};
        flowerbed.flowerbedItems.forEach(item => {
            if (item.flower) {
                if (flowerbed.flowers.findIndex(flower => flower.id === item.flower.id) in liArray) {
                    if (liArray[flowerbed.flowers.findIndex(flower => flower.id === item.flower.id)][item.color]) {
                        liArray[flowerbed.flowers.findIndex(flower => flower.id === item.flower.id)][item.color].count =
                            liArray[flowerbed.flowers.findIndex(flower => flower.id === item.flower.id)][item.color].count + 1;
                    } else {
                        liArray[flowerbed.flowers.findIndex(flower => flower.id === item.flower.id)][item.color] = {
                            flower: item.flower,
                            color: item.color,
                            count: 1,
                        };
                    }
                } else {
                    liArray[flowerbed.flowers.findIndex(flower => flower.id === item.flower.id)] = {};
                    liArray[flowerbed.flowers.findIndex(flower => flower.id === item.flower.id)][item.color] = {
                        flower: item.flower,
                        color: item.color,
                        count: 1,
                    };
                }
            }
        });

        return <div>
            <h2 style={{
                textAlign: 'center',
            }}>Список растений</h2>
            <table className={''}>
                {Object.keys(liArray).map((flower, key) => {
                    return <tr key={key}>
                        <td style={{
                            border: 'solid 1px black',
                        }}>
                            {parseInt(flower) + 1}.
                        </td>
                        <td style={{
                            border: 'solid 1px black',
                        }}>
                            {Object.values(liArray[flower]).length > 0
                                ? Object.values(liArray[flower])[0].flower.name
                                : ''}
                        </td>
                        <td style={{
                            border: 'solid 1px black',
                        }}>
                            {Object.values(liArray[flower]).map((item, key2) => {
                            let obj = colorNames.find(name => name.code === item.color);
                            let name = obj ? obj.name : 'Имя не найдено';
                            return <span key={key2}>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{name}</Tooltip>}
                            ><span style={{
                                width: 15, height: 15, borderRadius: 10, backgroundColor: item.color,
                                border: 'solid 1px black', display: 'inline-block', color: item.color,
                                marginLeft: 5, marginRight: 5,
                            }}></span></OverlayTrigger> - {item.count} шт.{key2 < Object.values(flower).length ? <br/> : ''}</span>
                        })}
                        </td>
                    </tr>
                })}
            </table>
        </div>
    }

    return <div className={'flowerbedMainForm'}>
        <Container>
            <Row>
                <Col>
                    <select ref={selectRef} onChange={async (e) => {
                        await setCurrentFlower(e.target.value);
                        setCurrentColor(parseInt(e.target.value) !== -1 ? null : placeholderFlowerColor);
                        setActiveItem(null);
                    }} value={currentFlower} className={'formField w300 mt-2'}>
                        <option value={null}>Растение</option>
                        <option value={-1}>Существующее растение</option>
                        {flowerbed.flowers.map((item, key) => {
                            return <option key={item.id} value={key}>{key + 1}. {item.name}</option>;
                        })}
                    </select>
                </Col>
                <Col>
                    {parseInt(currentFlower) === -1 && currentColor ?
                        <input placeholder={'Диаметр (см)'} type={'number'} onChange={(e) => {
                            setCustomDiameter(e.target.value);
                        }} className={'formField w300 '}/> : <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic" className={'formField w300'}>
                                Цвет
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {
                                    setCurrentColor(null)
                                }}>Цвет</Dropdown.Item>
                                {parseInt(currentFlower) === -1 &&
                                <Dropdown.Item onClick={() => setCurrentColor(placeholderFlowerColor)} style={{
                                    backgroundColor: placeholderFlowerColor,
                                    color: placeholderFlowerColor,
                                }}>{placeholderFlowerColor}</Dropdown.Item>}
                                {currentFlower !== null && parseInt(currentFlower) !== -1 && (Array.isArray(flowerbed.flowers[currentFlower].bloomColors) ?
                                    flowerbed.flowers[currentFlower].bloomColors :
                                    Object.values(flowerbed.flowers[currentFlower].bloomColors)).map((item, key) => {
                                    return <Dropdown.Item key={item + key} onClick={() => setCurrentColor(item)}
                                                          style={{
                                                              backgroundColor: item,
                                                              border: 'solid 1px #82B21E',
                                                              borderRadius: 10,
                                                              color: item,
                                                          }}>{item}</Dropdown.Item>;
                                })}
                            </Dropdown.Menu>
                        </Dropdown>}
                </Col>
                <Col>
                    {currentFlower && currentColor
                    && ((parseInt(currentFlower) === -1 && customDiameter) || parseInt(currentFlower) !== -1)
                    && <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">{colorNames.find(name => name.code === currentColor)
                            ? colorNames.find(name => name.code === currentColor).name
                            : 'Название не найдено'}</Tooltip>}>
                        <div style={{
                            width: (parseInt(currentFlower) === -1 ? customDiameter
                                : flowerbed.flowers[currentFlower].diameter) * ratio + 'px',
                            height: (parseInt(currentFlower) === -1 ? customDiameter
                                : flowerbed.flowers[currentFlower].diameter) * ratio + 'px',
                            backgroundColor: currentColor,
                            borderRadius: ((parseInt(currentFlower) === -1 ? customDiameter
                                : flowerbed.flowers[currentFlower].diameter) * ratio / 2) + 'px',
                            border: 'solid 1px black',
                            marginTop: 15,
                            display: 'inline-block',
                            lineHeight: (parseInt(currentFlower) === -1 ? customDiameter
                                : flowerbed.flowers[currentFlower].diameter) * ratio + 'px',
                        }}>+
                        </div>
                    </OverlayTrigger>}
                </Col>
            </Row>
        </Container>
        <Alert variant={'custom mt-3 mb-3'}>
            {!currentFlower && !currentColor && !activeItem ? 'Выберите растение и цвет.' :
                currentFlower && currentColor && !activeItem ? 'Кликните на площадь клумбы, чтобы расположить цветок на ней.' :
                    'Нажмите на иконку мусорного ведра для удаления или на новое место расположения цветка.'}
        </Alert>
        <div className={'flowerbedFormFlex'}>
            <div className={'flowerbedFormBlock'}>
                {tableRender()}
            </div>
            <div>
                {months.map(month => {
                    return <div key={month.number}
                                className={'flowerberFormMonth pointer ' + (month.number === currentMonth ? 'active' : '')}
                                onClick={() => {
                                    setCurrentMonth(month.number);
                                }}>
                        {month.short}
                    </div>;
                })}
            </div>
        </div>
        {listRender()}
        <button className={'createBtn mb-5'} onClick={handlePrint}>Распечатать</button>
        <div className={'print-content'}>
            <h1>Клумба "{flowerbed.name}"</h1>
            {months.map(month => {
                return <div key={month.number} style={{
                    height: '100%',
                }}>
                    <h2 style={{
                        textAlign: 'center',
                    }}>{month.name}</h2>
                    <p>Размер: {flowerbed.width} см х {flowerbed.height} см</p>
                    {tableRender(month.number)}
                </div>;
            })}
            {listRender()}
        </div>
    </div>;
}

export default connect((state /*, ownProps*/) => {
        return {};
    }
    , dispatch => (
        {}
    ))(FlowerbedForm)
