import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppStore from '../images/app_store.webp';
import GoogleStore from '../images/google_store.webp';
import {
    FacebookShareButton,
    OKShareButton,
    VKShareButton,
    WhatsappShareButton,
} from "react-share";

import VK from '../images/vk.webp'
import OK from '../images/ok.webp'
import FB from '../images/fb.webp'
import WA from '../images/wa.webp'

const Footer = function (props) {
    return <div className={'footer'}>
        <div className={'socialBlock'}>
            <a href={'https://instagram.com/arch.komarova'}><div>
                <FontAwesomeIcon icon={['fab', 'instagram']} />
            </div></a>
            <a href={'https://www.facebook.com/profile.php?id=100001196036293'}><div>
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
            </div></a>
            <a href={'https://vk.com/kursld'}><div>
                <FontAwesomeIcon icon={['fab', 'vk']} />
            </div></a>
        </div>
        <div className={'storeBlock'}>
            <a className={'storeBtn'} href={'https://apps.apple.com/us/app/flower-garden/id1559484487'}>
                <img src={AppStore} alt={'AppStore'} />
            </a>
            <a className={'storeBtn'} href={'https://play.google.com/store/apps/details?id=com.vladimirov.flowerbed'}>
                <img src={GoogleStore} alt={'PlayMarket'} />
            </a>
        </div>
        <div className={'requisiteBlock'}>
            <p className={'text-left'}>
                <a href={'mailto:wa21.ufa@gmail.com'}>wa21.ufa@gmail.com</a><br/>
                <a href={'tel:+79173884339'}>+7 (917) 388-43-39</a>
            </p>
        </div>
        <div className={'shareBlock ml-md-2'}>
            <p className={'text-center mb-1'}>Поделиться с друзьями</p>
            <WhatsappShareButton title={'Я проектирую во Flower Garden!'}
                                 url={'https://www.fg2020.ru/'} >
                <img src={WA} alt={'wa'} />
            </WhatsappShareButton>
            <FacebookShareButton quote={"Я проектирую во Flower Garden!"} hashtag={'#flowergarden'}
                                 url={'https://www.fg2020.ru/'}>
                <img src={FB} alt={'fb'} />
            </FacebookShareButton>
            <OKShareButton title={'Flower Garden'} description={'Я проектирую во Flower Garden!'}
                           url={'https://www.fg2020.ru/'}>
                <img src={OK} alt={'ok'} />
            </OKShareButton>
            <VKShareButton title={'Я проектирую во Flower Garden!'}
                           url={'https://www.fg2020.ru/'}>
                <img src={VK} alt={'vk'} />
            </VKShareButton>
        </div>
        <div className={'requisiteBlock ml-md-2'}>
            <p className={'text-left'}>
                ООО "САДОВОД"<br/>
                ИНН 0277954800<br/>
                ОГРН 1210200017950
            </p>
        </div>
    </div>;
}

export default connect((state /*, ownProps*/) => {
    return {};
}, dispatch => ({}))(Footer)
