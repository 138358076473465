import {Breadcrumb, Table, Button, Badge, InputGroup, Form} from 'react-bootstrap';
import {useAuth} from "../../context/provideAuth";
import {createRef, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import request from "../../tools/ajaxManager";
import CustomPagination from "../../components/customPagination";
import {months} from "../../tools/data";
import {serverAdUrl} from "../../tools/parameters";
import AdminAdTr from "../../components/AdminAdTr";

const AdminAds = function (props) {
    // state
    let auth = useAuth();
    const [data, setData] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [link, setLink] = useState('');
    const [isShow, setIsShow] = useState(true);
    const [type, setType] = useState('big_ad');

    const filesRef = createRef();

    // effects
    useEffect(() => {
        if (auth.token) request(
            '/partner/notice/',
            'GET',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                setData(response.data);
            },
            (error) => {
            },
        );
    }, [auth.token]);

    // functions
    function handleAdd(params = null, key = null) {
        if (auth.token) {
            let formData;
            if (params) formData = params;
            else {
                formData = new FormData();
                formData.append('link', link);
                formData.append('type', type);
                formData.append('is_show', isShow ? 1 : 0);
                formData.append('file', filesRef.current.files[0]);
            }

            request(
                '/partner/notice/',
                'POST',
                formData,
                {
                    "YT-AUTH-TOKEN": "YourTar " + auth.token,
                },
                (response) => {
                    let tmp = [...data];
                    if (!params)
                        tmp.push(response.data);
                    else tmp[key] = response.data;

                    if (response.isShow) {
                        for (let i = 0; i < tmp.length; i++) {
                            if (tmp[i].type === tmp[key].type && tmp[i].id !== response.data.id) {
                                tmp[i].isShow = false;
                            }
                        }
                    }

                    setIsAdd(false);
                    setData(tmp);
                    setLink('');
                    setType('bid_ad');
                    setIsShow(true);
                },
                (error) => {
                    alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
                },
            );
        }
    }
    function handleDelete(key) {
        if (auth.token) request(
            '/partner/notice/' + data[key].id,
            'DELETE',
            {},
            {
                "YT-AUTH-TOKEN": "YourTar " + auth.token,
            },
            (response) => {
                let tmp = [...data];
                tmp.splice(key, 1);
                setData(tmp);
            },
            (error) => {
                alert(error.message ?? 'Что-то пошло не так. Попробуйте повторить операцию позже.');
            },
        );
    }

    function renderAddRow() {
        if (isAdd) {
            return <tr>
                <td></td>
                <td>
                    <Form.Group controlId="name">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Ссылка
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control onChange={(e) => setLink(e.target.value)}
                                          type="text" />
                        </InputGroup>
                    </Form.Group>
                </td>
                <td>
                    <Form.Control placeholder="Выберите файл" type={'file'} ref={filesRef} />
                </td>
                <td>
                    <Form.Group controlId="name">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Тип
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" onChange={(e) => {
                                setType(e.target.value);
                            }}>
                                <option value={'big_ad'}>Большой баннер</option>
                                <option value={'small_ad'}>Маленький баннер</option>
                            </Form.Control>
                        </InputGroup>
                    </Form.Group>
                </td>
                <td></td>
                <td>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" defaultChecked={true} label="Показывать на сайте" onChange={(e) => {
                            setIsShow(e.target.checked);
                        }}/>
                    </Form.Group>
                </td>
                <td>
                    <Button className={'text-right mr-2'} variant="warning" size={'sm'} onClick={() => {
                        setIsAdd(false);
                        setLink('');
                        setIsShow(true);
                        setType('big_ad');
                    }}>
                        <FontAwesomeIcon icon={'undo'} className={'mr-2'}/>
                        Отмена
                    </Button>
                    <Button className={'text-right'} variant="success" size={'sm'}
                            onClick={() => handleAdd()}>
                        <FontAwesomeIcon icon={'check'} className={'mr-2'}/>
                        Сохранить
                    </Button>
                </td>
            </tr>;
        }
        else {
            return <tr>
                <td colSpan={7}>
                    <Button className={'text-right'} variant="success" size={'sm'}
                            onClick={() => setIsAdd(true)}>
                        <FontAwesomeIcon icon={'plus'} className={'mr-2'}/>
                        Добавить
                    </Button>
                </td>
            </tr>
        }
    }

    return <div className={'content'}>
        <h3>Список объявлений</h3>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Проект "Клумбы"</Breadcrumb.Item>
            <Breadcrumb.Item href="/admin">
                Административная панель
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Список объявлений</Breadcrumb.Item>
        </Breadcrumb>

        <div className="table-responsive">
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Ссылка</th>
                    <th>Изображение</th>
                    <th>Тип</th>
                    <th>Показов</th>
                    <th>Показывается</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {renderAddRow()}
                {data.map((item, key) => {
                    return <AdminAdTr num={key} key={item.id} data={item} delete={handleDelete} edit={handleAdd} />;
                })}
                </tbody>
            </Table>
        </div>
    </div>;
}

export default AdminAds;