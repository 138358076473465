import {connect} from "react-redux";
import {Navbar, Nav} from 'react-bootstrap';
import {useLocation, useHistory} from "react-router-dom";
import {useAuth} from "../context/provideAuth";
import logo from '../images/icon.png';

const routes = {
    left: [
        {
            link: '/flowerbed/create',
            title: 'Создать',
            auth: true,
        },
        {
            link: '/flowers',
            title: 'Растения',
            auth: true,
        },
        {
            link: '/flowerbeds',
            title: 'Проекты',
            auth: true,
        },
        {
            link: '/profile',
            title: 'Профиль',
            auth: true,
        },
        {
            link: '/service',
            title: 'Сервисы',
            auth: true,
        },
    ],
    right: [
        {
            link: '/admin',
            title: 'Админка',
            roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
        },
    ],
}

const Header = function (props) {
    // state
    const location = useLocation();
    let history = useHistory();
    let auth = useAuth();

    return <Navbar collapseOnSelect expand="lg" bg="custom" variant="light" className={'custom'}>
        <Navbar.Brand href="/">
            <img src={logo} alt={'icon'} className={'headerLogo'} />
            <span>Flower Garden</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
                {routes.left.map((item, key) => {
                    if ((item.auth && auth.user) || !item.auth) return <Nav.Link onClick={(e) => {
                        e.preventDefault();
                        history.push(item.link);
                    }} active={item.link === location.pathname} key={key}
                                     href={item.link}>{item.title}</Nav.Link>;
                    else return null;
                })}
            </Nav>
            <Nav>
                {routes.right.map((item, key) => {
                    if (!item.roles || (auth.user ? auth.user.roles.filter(subItem => item.roles.includes(subItem)) : []).length > 0)
                        return <Nav.Link  onClick={(e) => {
                            e.preventDefault();
                            history.push(item.link);
                        }} active={item.link === location.pathname}
                                     key={key} href={item.link}>{item.title}</Nav.Link>;
                    return null;
                })}
                {auth.user ? <Nav.Link className={'customHeaderBtn'} href="#" onClick={(e) => {
                    e.preventDefault();
                    props.onLogout();
                }}>
                    Выйти
                </Nav.Link> : <>
                    <Nav.Link href="/register" className={'customHeaderBtn'}>
                        Зарегистрироваться
                    </Nav.Link>
                    <Nav.Link href="/login" className={'customHeaderBtn'}>
                        Войти
                    </Nav.Link>
                </>}
            </Nav>
        </Navbar.Collapse>
    </Navbar>;
}

export default connect((state /*, ownProps*/) => {
    return {
        user: state.user,
    }
}, dispatch => ({
    onLogout: () => {
        dispatch({type: 'LOGOUT', payload: null})
    },
}))(Header);